import { useUser } from '../../../Context/useUser';
import Table from '../../../Components/Table/Table';
import API from '../../../Utils/api';
import styles from './PerformancePage.module.css';
import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { useVisitingUser } from '../../../Hooks/useVisitingUser';
import Utils from '../../../Utils';
import MixedBarLines from '../../../Components/Charts/Mixed/MixedBarLines';
import Line from '../../../Components/Charts/Line/Line';

const FixedNumber = ({ value }) => {
    if (!value) return null;
    return <div>{value.toFixed(5)}</div>;
};

const columns = [
    {
        Header: 'Workout Start',
        accessor: 'workoutStart',
        Cell: ({ value }) => <div>{new Date(value).toLocaleString('en-GB')}</div>
    },
    {
        Header: 'Workout End',
        accessor: 'workoutEnd',
        Cell: ({ value }) => <div>{new Date(value).toLocaleString('en-GB')}</div>
    },
    {
        Header: 'Training Duration (min)',
        accessor: 'trainingDurationMin'
    },
    {
        Header: 'Training Duration (sec)',
        accessor: 'trainingDurationSec'
    },
    {
        Header: 'Volume',
        accessor: 'volume'
    },
    {
        Header: 'CSS',
        accessor: 'css',
        Cell: FixedNumber
    },
    {
        Header: 'FTP',
        accessor: 'ftp',
        Cell: FixedNumber
    },
    {
        Header: 'NSS',
        accessor: 'nss',
        Cell: FixedNumber
    },
    {
        Header: 'Intensity Factor',
        accessor: 'if',
        Cell: FixedNumber
    },
    {
        Header: 'Daily Training Load',
        accessor: 'tl',
        Cell: FixedNumber
    },
    {
        Header: 'TSS',
        accessor: 'tss',
        Cell: FixedNumber
    },
    {
        Header: 'ATL',
        accessor: 'atl',
        Cell: FixedNumber
    },
    {
        Header: 'CTL',
        accessor: 'ctl',
        Cell: FixedNumber
    },
    {
        Header: 'TSB',
        accessor: 'tsb',
        Cell: FixedNumber
    },
    {
        Header: 'ACWR',
        accessor: 'acwr',
        Cell: FixedNumber
    }
];

export default function PerformancePage() {
    const { user } = useUser();

    const { athleteId } = useParams();

    const visitedUser = useVisitingUser(athleteId);

    const currentUser = athleteId ? visitedUser : user;

    const [data, setData] = useState([]);

    const fetchAndSetData = async currentUser => {
        if (!currentUser) return;
        const { data: performances } = await API.getUserWorkouts(currentUser._id);
        setData(
            performances
                .sort((a, b) => new Date(b.workoutStart) - new Date(a.workoutStart))
                .reverse()
        );
    };

    useEffect(() => {
        fetchAndSetData(currentUser);
    }, [currentUser]);

    const dataPerf = useMemo(() => {
        const labels = data.map(p => new Date(p.workoutStart).toLocaleDateString());

        const currentDateTimestamp = Date.now();

        const pastWorkouts = data.filter(
            workout => new Date(workout.workoutStart).getTime() <= currentDateTimestamp
        );

        // Retrieve all the workouts plus the common one to connect the graph
        const futureWorkouts = data.slice(pastWorkouts.length - 1);

        // Retrieve all the future workouts without the current one so there is no overlap between the past and future TSS bar in the chart
        const futureWorkoutsForTss = data.slice(pastWorkouts.length);

        const atlData = pastWorkouts.map(workout => ({
            x: new Date(workout.workoutStart).toLocaleDateString(),
            y: workout.atl
        }));

        const ctlData = pastWorkouts.map(workout => ({
            x: new Date(workout.workoutStart).toLocaleDateString(),
            y: workout.ctl
        }));

        const tsbData = pastWorkouts.map(workout => ({
            x: new Date(workout.workoutStart).toLocaleDateString(),
            y: workout.tsb
        }));

        const acwrData = pastWorkouts.map(workout => ({
            x: new Date(workout.workoutStart).toLocaleDateString(),
            y: workout.acwr
        }));

        const tssData = pastWorkouts.map(workout => ({
            x: new Date(workout.workoutStart).toLocaleDateString(),
            y: workout.tss
        }));

        const futureTSSData = futureWorkoutsForTss.map(workout => ({
            x: new Date(workout.workoutStart).toLocaleDateString(),
            y: workout.tss
        }));

        const futureAtlData = futureWorkouts.map(workout => ({
            x: new Date(workout.workoutStart).toLocaleDateString(),
            y: workout.atl
        }));
        const futureCtlData = futureWorkouts.map(workout => ({
            x: new Date(workout.workoutStart).toLocaleDateString(),
            y: workout.ctl
        }));
        const futureTsbData = futureWorkouts.map(workout => ({
            x: new Date(workout.workoutStart).toLocaleDateString(),
            y: workout.tsb
        }));
        const futureAcwrData = futureWorkouts.map(workout => ({
            x: new Date(workout.workoutStart).toLocaleDateString(),
            y: workout.acwr
        }));

        return {
            labels,
            datasets: [
                {
                    type: 'line',
                    label: 'Fatigue (ATL)',
                    borderColor: `rgb(240, 180, 96)`,
                    borderWidth: 3,
                    fill: false,
                    data: atlData,
                    spanGaps: true
                },
                {
                    type: 'line',
                    label: 'Future Fatigue (ATL)',
                    borderColor: `rgba(240, 180, 96, 0.5)`,
                    borderWidth: 3,
                    borderDash: [5, 5],
                    fill: false,
                    data: futureAtlData,
                    spanGaps: true
                },
                {
                    type: 'line',
                    label: 'Fitness (CTL)',
                    borderColor: `rgb(148, 148, 148)`,
                    borderWidth: 3,
                    fill: false,
                    data: ctlData,
                    spanGaps: true
                },
                {
                    type: 'line',
                    label: 'Future Fitness (CTL)',
                    borderColor: `rgb(148, 148, 148)`,
                    borderWidth: 3,
                    fill: false,
                    data: futureCtlData,
                    borderDash: [5, 5],
                    spanGaps: true
                },
                {
                    type: 'line',
                    label: 'Form (TSB)',
                    borderColor: `rgb(240, 221, 96)`,
                    borderWidth: 3,
                    fill: false,
                    data: tsbData,
                    spanGaps: true
                },
                {
                    type: 'line',
                    label: 'Future Form (TSB)',
                    borderColor: `rgb(240, 221, 96)`,
                    borderWidth: 3,
                    fill: false,
                    data: futureTsbData,
                    spanGaps: true,
                    borderDash: [5, 5]
                },
                {
                    type: 'bar',
                    label: 'Stress (TSS)',
                    backgroundColor: `rgb(89, 140, 240)`,
                    data: tssData,
                    maxBarThickness: 50
                },
                {
                    type: 'bar',
                    label: 'Future Stress (TSS)',
                    backgroundColor: `rgb(89, 140, 240, 0.3)`,
                    data: futureTSSData,
                    maxBarThickness: 50,
                }
            ],
            pastWorkouts,
            futureWorkouts,
            acwrData,
            futureAcwrData
        };
    }, [data]);

    const chartOptions = useMemo(() => {
        return {
            plugins: {
                title: {
                    display: true,
                    text: 'Performance'
                }
            },
            animations: {
                tension: {
                    duration: 1500,
                    easing: 'easeInOutBounce',
                    from: 1,
                    to: 0
                }
            }
        };
    }, []);

    const readinessChartDataset = useMemo(() => {
        return [
            {
                label: 'ACWR',
                data: dataPerf.acwrData,
                borderColor: `rgb(1,1,1)`,
                backgroundColor: `rgba((1,1,1), 0.5)`,
                spanGaps: true
            },
            {
                label: 'Future ACWR',
                data: dataPerf.futureAcwrData,
                borderColor: `rgb(1,1,1)`,
                backgroundColor: `rgba((1,1,1), 0.5)`,
                spanGaps: true,
                borderDash: [5, 5]
            }
        ];
    }, [dataPerf]);

    const annotations = useMemo(() => {
        const Acwr = data.map(w => w.acwr);

        // Filter out undefined values from the array
        const filteredAcwr = Acwr.filter(value => typeof value === 'number');

        // Perform the summation on the filtered array
        const Sum = filteredAcwr.reduce((a, b) => a + b, 0);

        // Calculate the average
        const avgAcwr = filteredAcwr.length > 0 ? Sum / filteredAcwr.length : 0;

        return [
            {
                type: 'box',
                xScaleID: 'x',
                yScaleID: 'y',
                yMin: 0.8,
                yMax: 1.5,
                backgroundColor: 'rgba(152, 235, 63, 0.3)',
                borderColor: 'rgba(152, 235, 63, 0.9)',
                borderWidth: 2
            },
            {
                type: 'line',
                borderColor: 'red',
                borderDashOffset: 0,
                borderWidth: 3,
                label: {
                    display: true,
                    content: 'Average: ' + avgAcwr,
                    position: 'end'
                },
                scaleID: 'y',
                value: avgAcwr
            }
        ];
    }, [data]);

    return (
        <div className={styles.pageWrap}>
            <h4 className={styles.title + ' island'}> Performance Analysis</h4>
            <div className={styles.dataTablesWrap + ' island'}>
                <Table columns={columns} data={data} />
            </div>
            <div className={styles.dataVisualizationWrap + ' island'}>
                <MixedBarLines data={dataPerf} options={chartOptions} />
            </div>
            <div className={styles.dataVisualizationWrap + ' island'}>
                <Line
                    title={'Readiness'}
                    titleY={'ACWR'}
                    titleX={'Workout Dates'}
                    datasets={readinessChartDataset}
                    labels={dataPerf.labels}
                    annotations={annotations}
                />
            </div>
        </div>
    );
}
