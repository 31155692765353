import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Filler,
    Tooltip,
    Legend
} from 'chart.js';

import { Line as LineChartJS } from 'react-chartjs-2';
import { useMemo } from 'react';
import annotationPlugin from 'chartjs-plugin-annotation';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler, annotationPlugin);

export default function Line ({ title, titleX, titleY, labels, datasets, className, annotations }) {
    const options = useMemo(
        () => ({
            responsive: true,
            plugins: {
                legend: {
                    position: 'top'
                },
                title: {
                    display: !!title,
                    text: title
                },
                annotation: {
                    annotations
                },
            },
            scales: {
                x: {
                    title: {
                        display: !!titleX,
                        text: titleX
                    }
                },
                y: {
                    title: {
                        display: !!titleY,
                        text: titleY
                    }
                },


            },


        }),
        [title, titleX, titleY, annotations]
    );

    return (
        <LineChartJS
            options={options}
            data={{ labels, datasets }}
            style={{ width: '100%' }}
            className={className}
        />
    );
}
