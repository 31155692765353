import styles from './ZonesPage.module.css';

import Line from '../../../Components/Charts/Line/Line';
import { useEffect, useMemo, useState } from 'react';
import Utils from '../../../Utils';
import { useUser } from '../../../Context/useUser';
import API from '../../../Utils/api';
import { useParams } from 'react-router';
import { useVisitingUser } from '../../../Hooks/useVisitingUser';
import Table from '../../../Components/Table/Table';

const { red, green, blue } = Utils.randomColorGenerator();

export default function ZonesPage () {
    const { user } = useUser();

    const { athleteId } = useParams();

    const visitedUser = useVisitingUser(athleteId);

    const currentUser = athleteId ? visitedUser : user;

    const [meterData, setMeterData] = useState([]);
    const [jonesData, setJonesData] = useState(null);
    const [t30Data, setT30Data] = useState();
    const [t3000Data, setT3000Data] = useState();
    const fetchAndSetData = async user => {
        if (!user) return;
        const [{ data: meterData }, { data: jonesData }, { data: t30t3000Data }] = await Promise.all([
            API.getTest50TestsByUserId(user._id),
            API.getJonesTestsByUserId(user._id),
            API.getAllTest30Tests(user._id)
        ]);
        setMeterData(meterData);
        setJonesData(jonesData[jonesData.length - 1] || null);

        const sortedT30Data = t30t3000Data
            .filter(item => item.time3000 === undefined) // Only consider T30 tests
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)); // Sort by date in descending order

        setT30Data(sortedT30Data.slice(0, 5)); //Last 5 T30 Tests by Date Desc

        const sortedT3000Data = t30t3000Data
            .filter(item => item.distance30 === undefined) // Only consider T3000 tests
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)); // Sort by date in descending order

        setT3000Data(sortedT3000Data.slice(0, 5));
    };

    useEffect(() => {
        fetchAndSetData(currentUser);
    }, [currentUser]);

    const datasetsMeters = useMemo(() => {
        return meterData.slice(0, 5).map(record => {
            const { red, green, blue } = Utils.randomColorGenerator();
            return {
                label: new Date(record.createdAt).toLocaleDateString(),
                data: [record.time50, record.time100, record.time200, record.time400],
                borderColor: `rgb(${red}, ${green}, ${blue})`,
                backgroundColor: `rgba(${red}, ${green}, ${blue}, 0.5)`
            };
        });
    }, [meterData]);

    const lastTest50 = useMemo(() => {
    return meterData[meterData.length - 1];
    }, [meterData])
    
    const lastTestJones = useMemo(() => {
    return jonesData;
    }, [jonesData])
    
    const lastTest30 = useMemo(() => {
    return t30Data ? t30Data[0] : null; //already sorted
    }, [t30Data])

    const lastTest3000 = useMemo(() => {
    return t3000Data? t3000Data[0] : null;
    }, [t3000Data])


    const labelsMeters = useMemo(() => ['50', '100', '200', '400'], []);

    const [datasetsJones, datasetsJones2, labelsJones] = useMemo(() => {
        if (!jonesData) return [[], [], []];
        return [
            [
                {
                    label: new Date(jonesData.createdAt).toLocaleDateString(),
                    data: [jonesData.timeA, jonesData.timeB, jonesData.timeC],
                    borderColor: `rgb(${red}, ${green}, ${blue})`,
                    backgroundColor: `rgba(${red}, ${green}, ${blue}, 0.5)`
                }
            ],
            [
                {
                    label: new Date(jonesData.createdAt).toLocaleDateString(),
                    data: [
                        jonesData.distanceA / jonesData.timeA,
                        jonesData.distanceB / jonesData.timeB,
                        jonesData.distanceC / jonesData.timeC
                    ],
                    borderColor: `rgb(${red}, ${green}, ${blue})`,
                    backgroundColor: `rgba(${red}, ${green}, ${blue}, 0.5)`
                }
            ],
            [jonesData.distanceA, jonesData.distanceB, jonesData.distanceC]
        ];
    }, [jonesData]);

    const columnsT30 = [
        {
            Header: 'Upload Date',
            accessor: 'submissionData',
            Cell: ({ row }) => <div>{new Date(row.original.createdAt).toLocaleDateString()}</div>
        },
        {
            Header: 'CSS',
            accessor: 'css',
            Cell: ({ row }) => <div>{row.original.css.toFixed(2)}</div>
        },
        {
            Header: 'Distance (m) for 30 min',
            accessor: 'distance30'
        }
    ];

    const columnsT3000 = [
        {
            Header: 'Upload Date',
            accessor: 'submissionData',
            Cell: ({ row }) => <div>{new Date(row.original.createdAt).toLocaleDateString()}</div>
        },
        {
            Header: 'CSS',
            accessor: 'css',
            Cell: ({ row }) => <div>{row.original.css.toFixed(2)}</div>
        },
        {
            Header: 'Time (sec) for 3000m',
            accessor: 'time3000'
        }
    ];

    const columnsZones = [
        {
            Header: 'Zone',
            accessor: 'title'
        },
        {
            Header: 'Velocity (m/sec)',
            columns: [
                {
                    Header: 'From',
                    accessor: 'velocityFrom'
                },
                {
                    Header: 'To',
                    accessor: 'velocityTo'
                }
            ]
        },
        {
            Header: 'Time (sec)',
            columns: [
                {
                    Header: 'From',
                    accessor: 'timeFrom'
                },
                {
                    Header: 'To',
                    accessor: 'timeTo'
                }
            ]
        }
    ];

    const [velocity50, setVelocity50] = useState(50);
    const [velocityZones50, setVelocityZones50] = useState([]);

    const [velocityJones, setVelocityJones] = useState(50);
    const [velocityZonesJones, setVelocityZonesJones] = useState([]);

    const [velocity30, setVelocity30] = useState(50);
    const [velocityZones30, setVelocityZones30] = useState([]);

    const [velocity3000, setVelocity3000] = useState(50);
    const [velocityZones3000, setVelocityZones3000] = useState([]);

    //Populate the velocity Zones when lastTest50 gets populated
    useEffect(() => {
        const fetchVelocityZonesTest50 = async () => {
            if (lastTest50) {
                try {
                    const response = await API.velocityZones(lastTest50.css, velocity50);
                    setVelocityZones50(response.data);
                } catch (error) {
                    console.error('Error fetching velocity zones:', error);
                }
            }
        };
    
        const fetchVelocityZonesTestJones = async () => {
            if (lastTestJones) {
                try {
                    const response = await API.velocityZones(lastTestJones.css, velocity50);
                    setVelocityZonesJones(response.data);
                } catch (error) {
                    console.error('Error fetching velocity zones:', error);
                }
            }
        };
    
        const fetchVelocityZonesTest30 = async () => {
            if (lastTest30) {
                try {
                    const response = await API.velocityZones(lastTest30.css, velocity50);
                    setVelocityZones30(response.data);
                } catch (error) {
                    console.error('Error fetching velocity zones:', error);
                }
            }
        };
    
        const fetchVelocityZonesTest3000 = async () => {
            if (lastTest3000) {
                try {
                    const response = await API.velocityZones(lastTest3000.css, velocity50);
                    setVelocityZones3000(response.data);
                } catch (error) {
                    console.error('Error fetching velocity zones:', error);
                }
            }
        };
    
        fetchVelocityZonesTest50();
        fetchVelocityZonesTestJones();
        fetchVelocityZonesTest30();
        fetchVelocityZonesTest3000();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastTest50]);

    
    const handleVelocityChange50 = async e => {
        const selectedVelocity = parseInt(e.target.value);
        setVelocity50(selectedVelocity);

        if (lastTest50) {
            try {
                setVelocityZones50((await API.velocityZones(lastTest50.css, selectedVelocity)).data);
            } catch (error) {
                console.error('Error fetching test data:', error);
            }
        }
    };
    
    const handleVelocityChangeJones = async e => {
        const selectedVelocity = parseInt(e.target.value);
        setVelocityJones(selectedVelocity);

        if (lastTest50) {
            try {
                setVelocityZonesJones((await API.velocityZones(lastTestJones.css, selectedVelocity)).data);
            } catch (error) {
                console.error('Error fetching test data:', error);
            }
        }
    };
   
    const handleVelocityChange30 = async e => {
        const selectedVelocity = parseInt(e.target.value);
        setVelocity30(selectedVelocity);

        if (lastTest50) {
            try {
                setVelocityZones30((await API.velocityZones(lastTest30.css, selectedVelocity)).data);
            } catch (error) {
                console.error('Error fetching test data:', error);
            }
        }
    };
    
    const handleVelocityChange3000 = async e => {
        const selectedVelocity = parseInt(e.target.value);
        setVelocity3000(selectedVelocity);

        if (lastTest50) {
            try {
                setVelocityZones3000((await API.velocityZones(lastTest3000.css, selectedVelocity)).data);
            } catch (error) {
                console.error('Error fetching test data:', error);
            }
        }
    };

    return (
        <div className={styles.pageWrapMain}>
             {meterData?.length > 0 && (<div className={styles.dataVisualizationWrapMain  + ' island'}>
                <div style={{width:'70%'}}>
                <h3>Test: 50 - 100 - 200 - 400</h3>
                <Line
                    // className={'island'}
                    title={'50-100-200-400'}
                    titleY={'Time (sec)'}
                    titleX={'Distance (m)'}
                    datasets={datasetsMeters}
                    labels={labelsMeters}
                />
                </div>
                <div className={styles.velocityContainerMain}>
                    <label htmlFor='velocity'>Velocity (m)</label>
                    <input type='number' className='form-control' value={velocity50} onChange={handleVelocityChange50} />
                    <Table
                        columns={columnsZones}
                        data={Utils.arrayfyObject(velocityZones50, {
                            keyToName: 'name'
                        })}
                        />
                        <div className={styles.cssContainer}>
                            <div>CSS: {lastTest50?.css.toFixed(2)}</div>
                </div>
                </div>
            </div>)}
            {jonesData && (<div className={styles.dataVisualizationWrapMain  + ' island'}>
            <div style={{width:'70%'}}>
                <h3 >Test: Jones</h3>
                <Line
                    // className={'island'}
                    title={'Critical Velocity'}
                    titleY={'Time (sec)'}
                    titleX={'Distance (m)'}
                    datasets={datasetsJones}
                    labels={labelsJones}
                />
                {/* Removing temporarily - doesn't make sense to have it here for now */}
                <Line
                    className={'island'}
                    title={'Velocity - Time'}
                    titleY={'Velocity (m/sec)'}
                    titleX={'Time (sec)'}
                    datasets={datasetsJones2}
                    labels={labelsJones}
                />
                </div>
                
                <div className={styles.velocityContainerMain}>
                    
                    <label htmlFor='velocity'>Velocity (m)</label>
                    <input type='number' className='form-control' value={velocityJones} onChange={handleVelocityChangeJones} />
                    <Table
                        columns={columnsZones}
                        data={Utils.arrayfyObject(velocityZonesJones, {
                            keyToName: 'name'
                        })}
                        />
                        <div className={styles.cssContainer}>
                            <div>CSS: {lastTestJones?.css.toFixed(2)}</div>
                </div>
                </div>
            </div>)}
            {t30Data?.length > 0 && (
                <div className={styles.dataVisualizationWrapMain + ' island'}>
                <div style={{width:'70%'}}>
                        <h3>Test: T30</h3>
                    <Table columns={columnsT30} data={t30Data} />
                    
                </div>
                <div className={styles.velocityContainerMain}>
                    <label htmlFor='velocity'>Velocity (m)</label>
                    <input type='number' className='form-control' value={velocity30} onChange={handleVelocityChange30} />
                    <Table
                        columns={columnsZones}
                        data={Utils.arrayfyObject(velocityZones30, {
                            keyToName: 'name'
                        })}
                        />
                         <div className={styles.cssContainer}>
                            <div>CSS: {lastTest30?.css.toFixed(2)}</div>
                </div>
                        
                </div>
                </div>)}
            {t3000Data?.length > 0 && (
                <div className={styles.dataVisualizationWrapMain+ ' island'}>
                    <div style={{width:'70%'}}>
                        
                        <h3>Test: T3000</h3>
                        <Table columns={columnsT3000} data={t3000Data} />
                    </div>
                    <div className={styles.velocityContainerMain}>
                    <label htmlFor='velocity'>Velocity (m)</label>
                    <input type='number' className='form-control' value={velocity3000} onChange={handleVelocityChange3000} />
                    <Table
                        columns={columnsZones}
                        data={Utils.arrayfyObject(velocityZones3000, {
                            keyToName: 'name'
                        })}
                        />
                        <div className={styles.cssContainer}>
                            <div>CSS: {lastTest3000?.css.toFixed(2)}</div>
                </div>
                </div>
                </div>
            )}
        </div>
    );
}
