import { Outlet } from 'react-router';
import { NavLink } from 'react-router-dom';

import styles from './TabPageLayout.module.css';

export default function TabPageLayout({ pages }) {
    const navs = pages.map(page => (
        <NavLink
            key={page.path}
            to={page.path}
            title={page.title}
            className={({ isActive }) => `${styles.link} ${isActive ? styles.linkActive : ''}`}
            end
        >
            {page.title}
        </NavLink>
    ));

    return (
        <>
            <div className={styles.linksWrap + ' island'}>{navs}</div>
            <div className={styles.content}>
                <Outlet />
            </div>
        </>
    );
}
