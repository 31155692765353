import { Outlet } from 'react-router';

import styles from './AthletePageLayout.module.css';

import AtheletePages from './AthletePages.json';

import Sidebar from '../../Components/Sidebar/Sidebar';

import water from './water.jpg';

export default function AthletePageLayout() {
    return (
        <div
            className={styles.athletePagesWrap}
            style={{
                background: `linear-gradient(rgba(255, 255, 255, 0.8), rgb(8, 72, 142)), url(${water})`,
                backgroundSize: 'cover'
            }}
        >
            <Sidebar pages={AtheletePages} />
            <div className={styles.athleteContent}>
                <Outlet />
            </div>
        </div>
    );
}
