import ProtectedPageLayout from './Layouts/ProtectedPageLayout/ProtectedPageLayout';
import AthletePageLayout from './Layouts/AthletePageLayout/AthletePageLayout';
import ProfilePage from './Pages/AthletePage/ProfilePage/ProfilePage';
import CalendarPage from './Pages/AthletePage/CalendarPage/CalendarPage';
import ZonesPage from './Pages/AthletePage/ZonesPage/ZonesPage';
import PerformancePage from './Pages/AthletePage/PerformancePage/PerformancePage';
import RaceAnalysisPage from './Pages/AthletePage/RaceAnalysisPage/RaceAnalysisPage';
import TabPageLayout from './Layouts/TabPageLayout/TabPageLayout';
import MeterTestPage from './Pages/AthletePage/ZonesPage/MeterTestPage';
import T30TestPage from './Pages/AthletePage/ZonesPage/T30TestPage';
import T3000TestPage from './Pages/AthletePage/ZonesPage/T3000TestPage';
import JonesTestPage from './Pages/AthletePage/ZonesPage/JonesTestPage';
import DashboardPage from './Pages/AthletePage/DashboardPage/DashboardPage';
import CoachPageLayout from './Layouts/CoachPageLayout/CoachPageLayout';
import { Navigate } from 'react-router';
import AthleteSearchPage from './Pages/CoachPage/AthleteSearchPage/AthleteSearchPage';
import MyCoachesPage from './Pages/AthletePage/MyCoachesPage/MyCoachesPage';
import CoachProfilePage from './Pages/CoachPage/ProfilePage/CoachProfilePage';
import AdminPageLayout from './Layouts/AdminPageLayout/AdminPageLayout';
import UserSearchPage from './Pages/AdminPage/UserSearchPage/UserSearchPage';
import FeedbackInspection from './Pages/AdminPage/FeedbackInspectionPage/FeedbackInspectionPage';
import FeedbackPage from './Pages/FeedbackPage/FeedbackPage';

function isAthlete(user) {
    return user.type === 'Athlete';
}

function isCoach(user) {
    return user.type === 'Coach';
}

function isAdmin(user) {
    return user.type === 'Admin';
}

export default function ProtectedRoutes(user) {
    user.type = user.type || 'Coach';
    return [
        {
            path: '/',
            page: <ProtectedPageLayout />,
            conditions: true,
            children: [
                isAthlete(user) && {
                    path: '',
                    page: <AthletePageLayout />,
                    children: [
                        {
                            path: '',
                            page: <Navigate to={'/dashboard'} />
                        },
                        {
                            path: 'dashboard',
                            page: <DashboardPage />
                        },
                        {
                            path: 'profile',
                            page: <ProfilePage />
                        },
                        {
                            path: 'zones',
                            page: (
                                <TabPageLayout
                                    pages={[
                                        { title: 'All', path: '' },
                                        { title: '50 - 100 - 200 - 400', path: 'meterTest' },
                                        { title: 'T30', path: 't30' },
                                        { title: 'T3000', path: 't3000' },
                                        { title: 'Jones', path: 'jones' }
                                    ]}
                                />
                            ),
                            children: [
                                {
                                    path: '',
                                    page: <ZonesPage />
                                },
                                {
                                    path: 'meterTest',
                                    page: <MeterTestPage />
                                },
                                {
                                    path: 't30',
                                    page: <T30TestPage />
                                },
                                {
                                    path: 't3000',
                                    page: <T3000TestPage />
                                },
                                {
                                    path: 'jones',
                                    page: <JonesTestPage />
                                }
                            ]
                        },
                        {
                            path: 'calendar',
                            page: <CalendarPage />
                        },
                        {
                            path: 'performance',
                            page: <PerformancePage />
                        },
                        // {
                        //     path: 'daily-plan',
                        //     page: <DailyPlanPage />
                        // },
                        {
                            path: 'race-analysis',
                            page: <RaceAnalysisPage />
                        },
                        // {
                        //     path: 'build-your',
                        //     page: <BuildYourMacrocyclePage />
                        // }
                        {
                            path: 'mycoaches',
                            page: <MyCoachesPage />
                        },
                        {
                            path: 'feedback',
                            page: <FeedbackPage />
                        }
                    ]
                },
                isCoach(user) && {
                    path: '',
                    page: <CoachPageLayout />,
                    children: [
                        {
                            path: '',
                            page: <Navigate to={'/coach_dashboard'} />
                        },
                        {
                            path: 'coach_dashboard',
                            page: <DashboardPage />
                        },
                        {
                            path: 'coach_profile',
                            page: <CoachProfilePage />
                        },
                        {
                            path: 'athletes',
                            page: <AthleteSearchPage />
                        },
                        {
                            path: 'athlete/:athleteId',
                            children: [
                                {
                                    path: '',
                                    page: <Navigate to={'dashboard'} />
                                },
                                {
                                    path: 'dashboard',
                                    page: <DashboardPage />
                                },
                                {
                                    path: 'profile',
                                    page: <ProfilePage />
                                },
                                {
                                    path: 'zones',
                                    page: (
                                        <TabPageLayout
                                            pages={[
                                                { title: 'All', path: '' },
                                                {
                                                    title: '50 - 100 - 200 - 400',
                                                    path: 'meterTest'
                                                },
                                                { title: 'T30', path: 't30' },
                                                { title: 'T3000', path: 't3000' },
                                                { title: 'Jones', path: 'jones' }
                                            ]}
                                        />
                                    ),
                                    children: [
                                        {
                                            path: '',
                                            page: <ZonesPage />
                                        },
                                        {
                                            path: 'meterTest',
                                            page: <MeterTestPage />
                                        },
                                        {
                                            path: 't30',
                                            page: <T30TestPage />
                                        },
                                        {
                                            path: 't3000',
                                            page: <T3000TestPage />
                                        },
                                        {
                                            path: 'jones',
                                            page: <JonesTestPage />
                                        }
                                    ]
                                },
                                {
                                    path: 'calendar',
                                    page: <CalendarPage />
                                },
                                {
                                    path: 'performance',
                                    page: <PerformancePage />
                                },
                                {
                                    path: 'race-analysis',
                                    page: <RaceAnalysisPage />
                                }
                            ]
                        },
                        {
                            path: 'feedback',
                            page: <FeedbackPage />
                        }
                    ]
                },
                isAdmin(user) && {
                    path: '',
                    page: <AdminPageLayout />,
                    children: [
                        {
                            path: '',
                            page: <Navigate to={'/admin_dashboard'} />
                        },
                        {
                            path: 'admin_dashboard',
                            page: <DashboardPage />
                        },
                        {
                            path: 'users',
                            page: <UserSearchPage />
                        },
                        {
                            path: 'athlete/:athleteId',
                            children: [
                                {
                                    path: '',
                                    page: <Navigate to={'dashboard'} />
                                },
                                {
                                    path: 'dashboard',
                                    page: <DashboardPage />
                                },
                                {
                                    path: 'profile',
                                    page: <ProfilePage />
                                },
                                {
                                    path: 'zones',
                                    page: (
                                        <TabPageLayout
                                            pages={[
                                                { title: 'All', path: '' },
                                                {
                                                    title: '50 - 100 - 200 - 400',
                                                    path: 'meterTest'
                                                },
                                                { title: 'T30', path: 't30' },
                                                { title: 'T3000', path: 't3000' },
                                                { title: 'Jones', path: 'jones' }
                                            ]}
                                        />
                                    ),
                                    children: [
                                        {
                                            path: '',
                                            page: <ZonesPage />
                                        },
                                        {
                                            path: 'meterTest',
                                            page: <MeterTestPage />
                                        },
                                        {
                                            path: 't30',
                                            page: <T30TestPage />
                                        },
                                        {
                                            path: 't3000',
                                            page: <T3000TestPage />
                                        },
                                        {
                                            path: 'jones',
                                            page: <JonesTestPage />
                                        }
                                    ]
                                },
                                {
                                    path: 'calendar',
                                    page: <CalendarPage />
                                },
                                {
                                    path: 'performance',
                                    page: <PerformancePage />
                                },
                                {
                                    path: 'race-analysis',
                                    page: <RaceAnalysisPage />
                                },
                                {
                                    path: 'mycoaches',
                                    page: <MyCoachesPage />
                                }
                            ]
                        },
                        {
                            path: 'coach/:coachId',
                            children: [
                                {
                                    path: '',
                                    page: <Navigate to={'coach_dashboard'} />
                                },
                                {
                                    path: 'coach_dashboard',
                                    page: <DashboardPage />
                                },
                                {
                                    path: 'coach_profile',
                                    page: <CoachProfilePage />
                                },
                                {
                                    path: 'athletes',
                                    page: <AthleteSearchPage />
                                }
                            ]
                        },
                        {
                            path: 'feedback-inspection',
                            page: <FeedbackInspection />
                        },
                        {
                            path: 'feedback',
                            page: <FeedbackPage />
                        },
                        {
                            path: '*',
                            page: <div>Error</div>
                        }
                    ]
                }
            ].filter(Boolean)
        }
    ];
}
