import Axios from 'axios';

export const URI = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8001/api';

export const axios = Axios.create({
    withCredentials: true,
    baseURL: URI,
    headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' },
    credentials: 'include'
});

export const setAuthToken = value => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${value}`;
};

const API = {};

axios.interceptors.response.use(
    function (res) {
        return res;
    },
    function (error) {
        console.log(error?.response?.data?.error);
        return Promise.reject(error);
    }
);

/** Auth */

API.refreshToken = () => axios.post(`${URI}/auth/accessToken`);
API.login = ({ email, password }) => axios.post(`${URI}/auth/login`, { email, password });
API.logout = () => axios.post(`${URI}/auth/logout`);
API.forgotPassword = ({ email }) => axios.post(`${URI}/auth/forgotPassword/${email}`);
API.recoverPassword = ({ recoverPasswordId, password }) =>
    axios.post(`${URI}/auth/recoverPassword/${recoverPasswordId}`, { password });

API.signup = ({ email, password, firstName, lastName, type }) =>
    axios.post('/auth/signup', {
        username: 'test' + Math.random() * 1000000,
        email,
        password,
        firstName,
        lastName,
        type
    });

API.createAccount = ({ email, firstName, lastName, type }) =>
    axios.post('/auth/createAccount', {
        email,
        firstName,
        lastName,
        type
    });

API.activateAccount = userId => axios.post(`/auth/${userId}/activate`);
API.deActivateAccount = userId => axios.post(`/auth/${userId}/deactivate`);

/** Feedback */
API.addFeedback = ({ name, email, subject, message, type }) =>
    axios.post(`/feedback`, { name, email, subject, message, type });
API.updateFeedback = ({ feedbackId, status }) => axios.put(`/feedback/${feedbackId}`, { status });

API.getFeedbacks = () => axios.get(`/feedback`);

/** User */

API.updateMyself = data => axios.put(`${URI}/users/me`, data);
API.updateUser = (userId, data) => axios.put(`${URI}/users/${userId}`, data);
API.getUserById = userId => axios.get(`${URI}/users/${userId}`);
API.getPossibleInvitations = () => axios.get(`${URI}/users/athletes`);
API.getAllUsers = () => axios.get(`${URI}/users`);

/** Workouts **/

//Get All  Workouts
API.getAllWorkouts = () => axios.get(`${URI}/workouts`);

//Get Workout by Id
API.getWorkoutById = workoutId => axios.get(`${URI}/workouts/${workoutId}`);

//Get Workout by UserId
API.getUserWorkouts = userId => axios.get(`${URI}/workouts/users/${userId}`);

//Get workouts for all workout types by UserId
API.getAllUserWorkoutTypes = userId => axios.get(`${URI}/workouts/allTypes/users/${userId}`);

//Create Workout for User
API.addUserWorkout = (userId, body) => axios.post(`${URI}/workouts/users/${userId}`, body);
API.removeWorkout = workoutId => axios.delete(`${URI}/workouts/${workoutId}`);
API.updateWorkout = (workoutId, body) => axios.put(`${URI}/workouts/${workoutId}`, body);

//Update a Workout by Id
API.updateWorkoutById = (workoutId, body) => axios.put(`${URI}/workouts/${workoutId}`, body);

//Delete a workout
API.DeleteWorkoutById = workoutId => axios.delete(`${URI}/workouts/${workoutId}`);

API.DeleteAllWorkoutsForUser = userId => axios.delete(`${URI}/workouts/user/${userId}`);

/** Races **/

// Get all races
API.getAllRaces = () => axios.get(`${URI}/races`);

// Get all races for a specific user
API.getRacesByUserId = userId => axios.get(`${URI}/races/users/${userId}`);

// Get a specific race by ID
API.getRaceById = raceId => axios.get(`${URI}/races/${raceId}`);

// Create a race
API.createRace = (userId, raceData) => axios.post(`${URI}/races/users/${userId}`, raceData);

// Create a race but no splits
API.createRaceNoSplits = raceData => axios.post(`${URI}/races`, raceData);

// Update an existing race
API.updateRace = (raceId, raceData) => axios.put(`${URI}/races/${raceId}`, raceData);

// Update splits for a race by ID
API.updateRaceSplits = (raceId, splitsData) =>
    axios.put(`${URI}/races/${raceId}/splits`, splitsData);

// Delete race by ID
API.deleteRaceById = raceId => axios.delete(`${URI}/races/${raceId}`);

/** Test30 **/

// Get all Test30 tests
API.getAllTest30Tests = () => axios.get(`${URI}/tests/tests30`);

// Get Test30 test by ID
API.getTest30TestById = test30Id => axios.get(`${URI}/tests/tests30/${test30Id}`);

// Get all Test30 tests for a user
API.getTest30TestsByUserId = userId => axios.get(`${URI}/tests/tests30/users/${userId}`);

// Create a new Test30 test
API.createTest30Test = (userId, test30Data) =>
    axios.post(`${URI}/tests/tests30/users/${userId}`, test30Data);

// Update Test30 test by ID
API.updateTest30TestById = (test30Id, test30Data) =>
    axios.put(`${URI}/tests/tests30/${test30Id}`, test30Data);

// Delete Test30 test by ID
API.deleteTest30TestById = test30Id => axios.delete(`${URI}/tests/tests30/${test30Id}`);

// Delete all Test30 tests for a user
API.deleteAllTest30TestsForUser = userId => axios.delete(`${URI}/tests/tests30/users/${userId}`);

/** Test50 **/

// Get all Test50 tests
API.getAllTest50Tests = () => axios.get(`${URI}/tests/tests50`);

// Get a specific Test50 test by ID
API.getTest50TestById = test50Id => axios.get(`${URI}/tests/tests50/${test50Id}`);

//Get a specific Test50 with newly calculated velocity zones
API.getTest50ByIdWithVelocity = (test50Id, velocity) =>
    axios.get(`${URI}/tests/tests50/${test50Id}/velocity/${velocity}`);

// Get all Test50 tests for a specific user ID
API.getTest50TestsByUserId = userId => axios.get(`${URI}/tests/tests50/users/${userId}`);

// Create a new Test50 test
API.createTest50Test = (userId, test50Data) =>
    axios.post(`${URI}/tests/tests50/users/${userId}`, test50Data);

// Update an existing Test50 test
API.updateTest50Test = (test50Id, test50Data) =>
    axios.put(`${URI}/tests/tests50/${test50Id}`, test50Data);

// Delete a Test50 test by ID
API.deleteTest50TestById = test50Id => axios.delete(`${URI}/tests/tests50/${test50Id}`);

// Delete all Test50 tests for a user
API.deleteAllTest50TestsForUser = userId => axios.delete(`${URI}/tests/tests50/users/${userId}`);

/** Jones Tests **/

// Get all Jones Tests
API.getAllJonesTests = () => axios.get(`${URI}/tests/testsJones`);

// Get a specific Jones test by ID
API.getJonesTestById = jonesTestId => axios.get(`${URI}/tests/testsJones/${jonesTestId}`);

// Get all Jones tests for a specific user ID
API.getJonesTestsByUserId = userId => axios.get(`${URI}/tests/testsJones/users/${userId}`);

// Create a new Jones test
API.createJonesTest = (userId, jonesTestData) =>
    axios.post(`${URI}/tests/testsJones/users/${userId}`, jonesTestData);

// Update an existing Jones test
API.updateJonesTest = (jonesTestId, jonesTestData) =>
    axios.put(`${URI}/tests/testsJones/${jonesTestId}`, jonesTestData);

// Delete a Jones test by ID
API.deleteJonesTestById = jonesTestId => axios.delete(`${URI}/tests/testsJones/${jonesTestId}`);

// Delete all Jones tests for a user
API.deleteAllJonesTestsForUser = userId => axios.delete(`${URI}/tests/testsJones/users/${userId}`);

/** Velocity Calculations **/

API.velocityZones = (currentCSS, velocity) =>
    axios.get(`${URI}/tests/velocity?css=${currentCSS}&velocity=${velocity}`);

/** Relationships **/

// Get all athletes associated with the logged-in coach
API.getAthletesById = coachId => axios.get(`${URI}/relationships/athletes/${coachId}`);

// Get all coaches associated with the logged-in athlete
API.getCoachesById = athleteId => axios.get(`${URI}/relationships/coaches/${athleteId}`);

// Get all pending relationship approvals for the logged-in user (either coach or athlete)
API.getMyPendingApprovals = () => axios.get(`${URI}/relationships/myPendingApprovals`);

// Get coaches associated with a specific athlete by athleteId
API.getCoachesForAthlete = athleteId =>
    axios.get(`${URI}/relationships/athlete/${athleteId}/coaches`);

// Get athletes associated with a specific coach by coachId
API.getAthletesForCoach = coachId => axios.get(`${URI}/relationships/coach/${coachId}/athletes`);

// Send a request from a coach to be an athlete's coach
API.requestToAthlete = (athleteEmail, coachId) =>
    axios.post(`${URI}/relationships/request/athlete/${athleteEmail}/coach/${coachId}`);

// Send a request from an athlete to be coached by a coach
API.requestToCoach = (coachId, athleteId) =>
    axios.post(`${URI}/relationships/request/coach/${coachId}/athlete/${athleteId}`);

// Approve a relationship by its ID with the logged-in user (either coach or athlete)
API.approveRelationshipById = relationshipId =>
    axios.patch(`${URI}/relationships/relationship/${relationshipId}/approve`);

// Remove a relationship by its ID with the logged-in user (either coach or athlete)
API.deleteRelationshipById = relationshipId =>
    axios.patch(`${URI}/relationships/relationship/${relationshipId}/remove`);

// Approve a relationship between an athlete and a coach (for admins)
API.approveRelationship = (athleteId, coachId) =>
    axios.patch(`${URI}/relationships/approve`, { athleteId, coachId });

// Delete a relationship between an athlete and a coach (for admins)
API.deleteRelationship = (athleteId, coachId) =>
    axios.patch(`${URI}/relationships/delete`, { athleteId, coachId });

// Delete a relationship record by its ID (for developers, testing purposes)
API.deleteRelationshipRecordById = relationshipId =>
    axios.delete(`${URI}/relationships/relationship/delete/${relationshipId}`);

API.createNewAthleteThroughRelationship = (userData, coachId) =>
    axios.post(`${URI}/relationships/coach/${coachId}`, userData);

export default API;
