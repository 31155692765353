import { useFieldArray, useForm } from 'react-hook-form';

import Modal from '../../../Components/Modal/Modal';

import styles from './CalendarPage.module.css';
import API from '../../../Utils/api';
import { IoCloseSharp } from 'react-icons/io5';

export default function UpdateRace({ data: defaultValues, closeCB, onSuccess, onFailure }) {
    const { register, handleSubmit, control } = useForm({
        defaultValues: defaultValues
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'splits'
    });

    const onEventUpdate = async newData => {
        try {
            newData.raceDate = new Date(newData.raceDate).toUTCString();
            const { data } = await API.updateRace(defaultValues._id, newData);
            onSuccess('Race updated!', data);
        } catch (err) {
            console.log(err);
            onFailure("Couldn't update race!");
        }
    };

    const onEventRemove = async race => {
        try {
            await API.deleteRaceById(race._id);
            onSuccess('Race removed!', defaultValues);
        } catch (err) {
            console.log(err);
            onFailure("Couldn't remove race!");
        }
    };

    const onAddSplit = e => {
        append({ volume: 50, duration: 0, T: 0 });
    };

    const onRemoveSplit = id => {
        const splitToRemove = fields.findIndex(split => split.id === id);
        remove(splitToRemove);
    };

    return (
        <Modal
            closeCB={closeCB}
            title={'Update Race'}
            footer={
                <span className={styles.row}>
                    <button className='btn btn-danger' onClick={() => onEventRemove(defaultValues)}>
                        Remove
                    </button>
                    <button className='btn btn-primary' onClick={handleSubmit(onEventUpdate)}>
                        Update
                    </button>
                </span>
            }
        >
            <form className={styles.form}>
                <div className='form-group'>
                    <label htmlFor='raceDate'>Race Date</label>
                    <input
                        type='date'
                        {...register('raceDate', { required: true })}
                        className='form-control'
                    />
                </div>
                <div className='form-group'>
                    <label htmlFor='raceType'>Race Type</label>
                    <select className='form-control' {...register('raceType', { required: true })}>
                        <option value={'50'}>50</option>
                        <option value={'100'}>100</option>
                        <option value={'200'}>200</option>
                        <option value={'400'}>400</option>
                        <option value={'800'}>800</option>
                        <option value={'1500'}>1500</option>
                    </select>
                </div>
                <div className='form-group'>
                    <label htmlFor='raceLocation'>Location</label>
                    <input
                        type='text'
                        {...register('raceLocation', { required: true })}
                        className='form-control'
                    />
                </div>
                <div className='form-group'>
                    <label htmlFor='splits'>Splits</label>
                    {fields.map((field, index) => (
                        <div className={`${styles.row} form-row`}>
                            <IoCloseSharp
                                className={styles.removeButton}
                                onClick={() => onRemoveSplit(field.id)}
                            />
                            <div className='form-group'>
                                <label htmlFor='volume'>Volume</label>
                                <input
                                    key={field.id}
                                    type='text'
                                    {...register(`splits.${index}.volume`)}
                                    className='form-control'
                                />
                            </div>
                            <div className='form-group'>
                                <label htmlFor='duration'>Duration</label>
                                <input
                                    key={field.id}
                                    type='text'
                                    {...register(`splits.${index}.duration`)}
                                    className='form-control'
                                />
                            </div>
                            <div className='form-group'>
                                <label htmlFor='T'>T</label>
                                <input
                                    key={field.id}
                                    type='text'
                                    {...register(`splits.${index}.T`)}
                                    className='form-control'
                                />
                            </div>
                        </div>
                    ))}
                    <div className={`${styles.addButton}`}>
                        <button
                            type='button'
                            onClick={onAddSplit}
                            className={`btn btn-primary ${styles.addButton}`}
                        >
                            Add
                        </button>
                    </div>
                </div>
            </form>
        </Modal>
    );
}
