import { useEffect, useState } from 'react';
import API from '../Utils/api';

export const useVisitingUser = userId => {
    const [visitingUser, setVisitingUser] = useState(null);

    useEffect(() => {
        if (!userId) return;
        API.getUserById(userId).then(({ data }) => setVisitingUser(data));
    }, [userId]);

    return visitingUser;
};
