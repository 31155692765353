import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import 'aos/dist/aos.css';
import 'react-toastify/dist/ReactToastify.css';
import './style.css';
import { useEffect } from 'react';

import AOS from 'aos';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { UserProvider, useUser } from './Context/useUser';
import ProtectedRoutes from './ProtectedRoutes';
import PublicRoutes from './PublicRoutes';

const routeGenerator = routes => {
    const elementRoutes = routes.map(route => (
        <Route key={route.path} exact path={route.path} element={route.page}>
            {route.children ? routeGenerator(route.children) : null}
        </Route>
    ));
    // elementRoutes.push(<Route key={'/*'} exact path='/*' element={<div>Error page</div>} />);

    return elementRoutes;
};

const RouteDispatch = () => {
    const { user } = useUser();
    return (
        <Routes>
            {user ? routeGenerator(ProtectedRoutes(user)) : routeGenerator(PublicRoutes())}
        </Routes>
    );
};

function App() {
    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <UserProvider>
            <Router>
                <Routes>
                    <Route exact path='/*' element={<RouteDispatch />} />
                </Routes>
            </Router>
        </UserProvider>
    );
}

export default App;
