import { useForm } from 'react-hook-form';
import API from '../../../Utils/api';

import styles from './AthleteSearchPage.module.css';
import { ToastContainer, toast } from 'react-toastify';

export default function InviteAthleteModal({ onClose, coachId }) {
    const { register, handleSubmit } = useForm();

    const addAthlete = async ({ email }) => {
        try {
            await API.requestToAthlete(email, coachId);
            onClose?.();
            toast.success('OK', {
                position: 'bottom-center'
            });
        } catch (error) {
            toast.error(error?.response?.data?.error, {
                position: 'bottom-center'
            });
        }
    };

    return (
        <div className={styles.athleteModalWrap}>
            <ToastContainer />
            <form className={styles.form}>
                <div className='form-group'>
                    <label htmlFor='email'>Athlete's Email</label>
                    <input {...register('email')} className='form-control' />
                </div>
            </form>
            <button className='btn btn-primary' onClick={handleSubmit(addAthlete)}>
                Add Athlete
            </button>
        </div>
    );
}
