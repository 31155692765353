import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import { useMemo, useState } from 'react';

import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import styles from './CalendarPage.module.css';
import { useUser } from '../../../Context/useUser';
import { useEffect } from 'react';
import API from '../../../Utils/api';
import { ToastContainer, toast } from 'react-toastify';
import Utils from '../../../Utils';
import CreateWorkout from './CreateWorkout';
import UpdateWorkout from './UpdateWorkout';
import CreateChoice from './CreateChoice';
import CreateRace from './CreateRace';
import UpdateRace from './UpdateRace';
import { useParams } from 'react-router';
import { useVisitingUser } from '../../../Hooks/useVisitingUser';
import ShowRecap from './ShowRecap';

const localizer = momentLocalizer(moment);

const typeToForm = {
    workout_CREATE: CreateWorkout,
    race_CREATE: CreateRace,
    workout_UPDATE: UpdateWorkout,
    race_UPDATE: UpdateRace,
    recap_UPDATE: ShowRecap,
    CREATE: CreateChoice
};

export default function CalendarPage () {
    const { user } = useUser();

    const { athleteId } = useParams();

    const visitedUser = useVisitingUser(athleteId);

    const currentUser = athleteId ? visitedUser : user;

    const [events, setEvents] = useState(null);
    const [onCreateEventModal, setOnCreateEventModal] = useState(null);

    const fetchEvents = async user => {
        if (!user) return;
        const [{ data: workouts }, { data: races }] = await Promise.all([
            API.getUserWorkouts(user._id),
            API.getRacesByUserId(user._id)
        ]);

        const allEvents = [
            ...workouts.map(event => ({
                ...event,
                title: `Swimming workout ${new Date(event.workoutStart).toLocaleDateString(
                    'el-gr'
                )}`,
                type: 'workout',
                start: new Date(event.workoutStart),
                end: new Date(event.workoutEnd),
                workoutStart: Utils.dateToDatetimeFormat(new Date(event.workoutStart)),
                workoutEnd: Utils.dateToDatetimeFormat(new Date(event.workoutEnd))
            })),
            ...races.map(event => ({
                ...event,
                title: `Race ${new Date(event.raceDate).toLocaleDateString('el-gr')}`,
                type: 'race',
                raceDate: Utils.dateToSimpleDateFormat(new Date(event.raceDate)),
                allDay: true,
                start: new Date(event.raceDate),
                end: new Date(event.raceDate)
            }))
        ];

        setEvents(allEvents);
    };

    useEffect(() => {
        fetchEvents(currentUser);
    }, [currentUser]);

    const onEventResize = data => {
        const { start, end } = data;

        setEvents(currentEvents => {
            const firstEvent = {
                start: new Date(start),
                end: new Date(end)
            };
            return [...currentEvents, firstEvent];
        });
    };

    const onEventDrop = data => {
        const {
            start,
            end,
            event: { id }
        } = data;

        setEvents(currentEvents => {
            const currEvent = currentEvents.find(ev => ev.id === id);
            currEvent.start = start;
            currEvent.end = end;
            return [...currentEvents];
        });
    };

    const onSuccess = async (message, data) => {
        toast.success(message, {
            position: 'bottom-center'
        });
        setEvents([]);
        fetchEvents(currentUser);
        setOnCreateEventModal(null);
    };

    const onFailure = message => {
        toast.error(message, {
            position: 'bottom-center'
        });
    };

    const onDoubleClickEvent = data => {
        data.type = `${data.type}_UPDATE`;
        setOnCreateEventModal(data);
    };

    const onSelectSlot = data => {
        data.type = `CREATE`;
        setOnCreateEventModal(data);
    };

    const onSelecting = data => {
        data.type = `${data.type}_UPDATE`;
        setOnCreateEventModal(data);
    };

    const onEventChosen = data => {
        data.type = `${data.type}_CREATE`;
        setOnCreateEventModal(data);
    };

    const CreateEventModal = () => {
        if (!onCreateEventModal) return null;

        const Modal = typeToForm[onCreateEventModal.type];

        if (!Modal) return null;

        return (
            <Modal
                user={currentUser}
                data={onCreateEventModal}
                closeCB={() => {
                    setEvents([]);
                    fetchEvents(currentUser);
                    setOnCreateEventModal(false);
                }}
                onFailure={onFailure}
                onSuccess={onCreateEventModal.type === 'CREATE' ? onEventChosen : onSuccess}
            />
        );
    };

    const eventPropGetter = (event, start, end, isSelected) => {
        // if (event.type === 'race') console.log(event);
        return {
            className: styles[`calendarType-${event.type}`]
        };
    };


    const sEvents = useMemo(() => {
        if (!events) return [];

        const res = {};

        for (const event of events) {
            if (event.type !== 'workout') continue;
            const actualDate = new Date(event.workoutStart);
            actualDate.setDate(actualDate.getDate() + 7 - actualDate.getDay())
            const key = actualDate.toDateString();
            if (!res[key]) {
                res[key] = {
                    start: new Date(key),
                    end: new Date(key),
                    title: `Weekly Recap`,
                    type: 'recap',
                    scheduled: 0,
                    completed: 0,
                    events: []
                }
            }
            res[key].events.push(event);
            res[key].scheduled += 1;
            if (event.atl) res[key].completed += 1;
        }
        return Object.values(res);
    }, [events]);

    if (!events) return null;

    console.log(events);

    return (
        <>
            <ToastContainer draggable={'mouse'} />
            <Calendar
                className='island'
                defaultView='month'
                events={[...events, ...sEvents]}
                onSelectEvent={onSelecting}
                localizer={localizer}
                onEventDrop={onEventDrop}
                onSelectSlot={onSelectSlot}
                selectable
                eventPropGetter={eventPropGetter}
                resizable={false}
            />
            <CreateEventModal />
        </>
    );
}
