import { Outlet } from 'react-router';
import Footer from '../../Components/Footer/Footer';
import NavItemsProtected from './NavItemsProtected.json';
import NavbarSmall from '../../Components/Navbar/NavbarSmall';

export default function ProtectedPageLayout() {
    return (
        <>
            {/* <div style={{backgroundColor: 'rgb(38, 63, 73)', height: '60px'}}>
            <NavbarSmall items={NavItemsProtected}/>
        </div> */}
            <Outlet />
            {/* <Footer/> */}
        </>
    );
}
