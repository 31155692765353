import { Outlet } from "react-router";
import NavItemsPublic from "./NavItemsPublic.json"
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";

export default function PublicPageLayout() {
    return <>
        <div style={{backgroundColor: 'rgb(38, 63, 73)', height: '80px'}}>
            <Navbar items={NavItemsPublic}/>
        </div>
        <Outlet/>
        <Footer/>
    </>
}