import { useForm } from 'react-hook-form';

import Modal from '../../../Components/Modal/Modal';

import styles from './CalendarPage.module.css';

export default function CreateChoice({ data, closeCB, onSuccess }) {
    const { register, handleSubmit } = useForm({
        defaultValues: data
    });

    return (
        <Modal
            closeCB={closeCB}
            title={'Create Event'}
            footer={
                <button className='btn btn-primary' onClick={handleSubmit(onSuccess)}>
                    Proceed
                </button>
            }
        >
            <form className={styles.form}>
                <div className='form-group'>
                    <label htmlFor='type'>Type of event</label>
                    <select className='form-control' {...register('type', { required: true })}>
                        <option value={'workout'}>Workout</option>
                        <option value={'race'}>Race</option>
                    </select>
                </div>
            </form>
        </Modal>
    );
}
