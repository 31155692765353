import { useForm } from 'react-hook-form';

import styles from './CoachProfilePage.module.css';
import { useUser } from '../../../Context/useUser';
import { useMemo, useState } from 'react';
import API from '../../../Utils/api';

import Avatar from 'react-avatar';
import { ToastContainer, toast } from 'react-toastify';
import { useParams } from 'react-router';
import { useVisitingUser } from '../../../Hooks/useVisitingUser';

const targetTimeTransform = obj => {
    let times = 0;
    if (obj?.hours && !isNaN(obj.hours)) times += 3600 * obj.hours;
    if (obj?.minutes && !isNaN(obj.minutes)) times += 60 * obj.minutes;
    if (obj?.seconds && !isNaN(obj.seconds)) times += obj.seconds;
    return times;
};

const userTargetTimeTransform = targetTime => {
    if (!targetTime || typeof targetTime !== 'number') return targetTime;

    const obj = {};

    if (targetTime >= 3600) {
        obj.hours = Math.floor(targetTime / 3600);
        targetTime = targetTime % 3600;
    }

    obj.minutes = Math.floor(targetTime / 60);
    obj.seconds = targetTime % 60;

    return obj;
};

export default function CoachProfilePage() {
    const { user, setUser } = useUser();

    const { coachId } = useParams();

    const visitedUser = useVisitingUser(coachId);

    const currentUser = coachId ? visitedUser : user;

    const [editMode, setEditMode] = useState(false);
    const toggleEditMode = () => {
        setEditMode(prevEditMode => !prevEditMode);
    };

    if (currentUser) currentUser.targetTime = userTargetTimeTransform(currentUser.targetTime);
    const { register, handleSubmit, watch, reset } = useForm({
        defaultValues: currentUser
    });

    const { register: registerName, handleSubmit: handleSubmitName } = useForm({
        defaultValues: currentUser
    });

    const [height, weight, birthYear, athleteType, distance] = watch([
        'height',
        'weight',
        'birthYear',
        'athleteType',
        'distance'
    ]);

    const age = useMemo(
        () => (birthYear < 1990 ? null : new Date().getFullYear() - birthYear),
        [birthYear]
    );

    const updateUser = async userD => {
        userD.targetTime = targetTimeTransform(userD.targetTime);
        try {
            const { data } = await API.updateMyself(userD);
            setUser({ ...currentUser, ...data });
            setEditMode(false);
            toast.success(`User data were updated successfully!`, {
                position: 'bottom-center'
            });
        } catch {
            toast.error(`Couldn't update user data`, {
                position: 'bottom-center'
            });
        }
    };

    const updateUserName = async data => {
        const d = await API.updateMyself(data);
        console.log(d);
    };

    if (!currentUser) return;

    if (editMode) {
        return (
            <div className={styles.pageWrap}>
                <div style={{ position: 'absolute' }}>
                    <ToastContainer />
                </div>
                <div className={styles.leftSide + ' island'}>
                    <form className={styles.form}>
                        <div className='form-row'>
                            <div className='form-group'>
                                <label htmlFor='firstName'>First Name</label>
                                <input {...register('firstName')} className='form-control' />
                            </div>
                            <div className='form-group'>
                                <label htmlFor='lastName'>Last Name</label>
                                <input {...register('lastName')} className='form-control' />
                            </div>
                        </div>
                    </form>
                    <button className='btn btn-primary' onClick={toggleEditMode}>
                        {editMode ? 'View Profile' : 'Edit Profile'}
                    </button>
                </div>
                <div className={styles.rightSide + ' island'}>
                    <form className={styles.form}>
                        <div className='form-row'>
                            <div className='form-group'>
                                <label htmlFor='gender'>Gender</label>
                                <select className='form-control' {...register('gender')}>
                                    <option value={null}></option>
                                    <option value={'Male'}>Male</option>
                                    <option value={'Female'}>Female</option>
                                    <option value={'Other'}>Other</option>
                                </select>
                            </div>
                            <div className='form-group'>
                                <label htmlFor='birthYear'>Birth Year</label>
                                <input
                                    type='number'
                                    min={0}
                                    {...register('birthYear', {
                                        min: 0,
                                        max: new Date().getFullYear()
                                    })}
                                    className='form-control'
                                />
                            </div>
                        </div>
                        <div className='form-row'>
                            <div className='form-group'>
                                <label htmlFor='height'>Height (cm)</label>
                                <input
                                    type='number'
                                    min={0}
                                    {...register('height')}
                                    className='form-control'
                                />
                            </div>
                            <div className='form-group'>
                                <label htmlFor='weight'>Weight (kg)</label>
                                <input
                                    type='number'
                                    min={0}
                                    {...register('weight')}
                                    className='form-control'
                                />
                            </div>
                            <div className='form-group'>
                                <label htmlFor='sittingHeight'>Sitting Height (cm)</label>
                                <input
                                    type='number'
                                    min={0}
                                    {...register('sittingHeight')}
                                    className='form-control'
                                />
                            </div>
                            <div className='form-group'>
                                <label htmlFor='handOpening'>Hand opening (cm)</label>
                                <input
                                    type='number'
                                    min={0}
                                    {...register('handOpening')}
                                    className='form-control'
                                />
                            </div>
                        </div>

                        <div className='form-row'>
                            <div className='form-group'>
                                <label htmlFor='bmp'>BMP (Basic Metabolic Panel)</label>
                                <input
                                    type='number'
                                    disabled={true}
                                    defaultValue={
                                        weight && weight !== '' && height && height !== '' && age
                                            ? (10 * weight + 6.25 * height - 5 * age + 5).toFixed(2)
                                            : null
                                    }
                                    {...register('bmp')}
                                    className='form-control'
                                />
                            </div>
                            <div className='form-group'>
                                <label htmlFor='bmi'>BMI (Body Mass Index)</label>
                                <input
                                    type='number'
                                    disabled={true}
                                    defaultValue={
                                        weight &&
                                        weight !== '' &&
                                        height &&
                                        height !== '' &&
                                        height !== '0'
                                            ? (weight / ((height / 100) * (height / 100))).toFixed(
                                                  2
                                              )
                                            : null
                                    }
                                    {...register('bmi')}
                                    className='form-control'
                                />
                            </div>
                            <div className='form-group'>
                                <label htmlFor='hrMax'>Hr max</label>
                                <input
                                    type='number'
                                    disabled={true}
                                    defaultValue={age ? 220 - age : null}
                                    {...register('hrMax')}
                                    className='form-control'
                                />
                            </div>
                        </div>
                        <button className='btn btn-primary' onClick={handleSubmit(updateUser)}>
                            Update
                        </button>
                    </form>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.pageWrap}>
            <div style={{ position: 'absolute' }}>
                <ToastContainer />
            </div>
            <div className={styles.leftSide + ' island'}>
                <Avatar name={`${currentUser.firstName} ${currentUser.lastName}`} round={true} />
                <div className={styles.name}>
                    {currentUser.firstName} {currentUser.lastName}
                </div>
                <button className='btn btn-primary' onClick={toggleEditMode}>
                    {editMode ? 'View Profile' : 'Edit Profile'}
                </button>
                <div className={styles.email}>{currentUser.email}</div>
                <div className={styles.about}>
                    <div className={styles.aboutTitle}>About</div>
                    <div className={styles.aboutCntent}>
                        {currentUser.about || 'about the athlete here'}
                    </div>
                    {currentUser.type === 'Athlete' && (
                        <div className={styles.aboutCntent}>{currentUser.currentCSS}</div>
                    )}
                </div>
            </div>
            <div className={styles.rightSidePreview}>
                <div className={`${styles.rightSidePreviewItem} island`}>
                    <h4>General</h4>
                    <div className={styles.rightSidePreviewItemInner}>
                        Gender: <span>{currentUser.gender || 'Not set'}</span>
                    </div>
                    <div className={styles.rightSidePreviewItemInner}>
                        Birth Year: <span>{currentUser.birthYear || 'Not set'}</span>
                    </div>
                </div>
                <div className={`${styles.rightSidePreviewItem} island`}>
                    <h4>Biometrics</h4>
                    <div className={styles.rightSidePreviewItemInner}>
                        Height (cm): <span>{currentUser.height || 'Not set'}</span>
                    </div>
                    <div className={styles.rightSidePreviewItemInner}>
                        Weight (kg): <span>{currentUser.weight || 'Not set'}</span>
                    </div>
                    <div className={styles.rightSidePreviewItemInner}>
                        Sitting Height (cm): <span>{currentUser.sittingHeight || 'Not set'}</span>
                    </div>
                    <div className={styles.rightSidePreviewItemInner}>
                        Hand opening (cm): <span>{currentUser.handOpening || 'Not set'}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}
