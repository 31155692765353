import { useEffect, useMemo, useState } from 'react';
import API from '../../../Utils/api';
import Table from '../../../Components/Table/Table';
import { useNavigate } from 'react-router';

import styles from './UserSearchPage.module.css';
import Modal from '../../../Components/Modal/Modal';
import InviteUserModal from './InviteUserModal';

import { ToastContainer, toast } from 'react-toastify';

export default function UserSearchPage() {
    const [users, setUsers] = useState(null);
    const [inviteUserModal, setInviteUserModal] = useState(null);

    const [search, setSearch] = useState('');

    const switchAccountStatus = async (e, user) => {
        e.stopPropagation();
        try {
            const foundUser = users.find(u => u._id === user._id);
            if (user.status === 'Active') {
                await API.deActivateAccount(user._id);
                foundUser.status = 'Inactive';
            } else {
                await API.activateAccount(user._id);
                foundUser.status = 'Active';
            }
            setUsers([...users]);
            toast.success('User was successfully updated!', {
                position: 'bottom-center'
            });
        } catch (error) {
            toast.error(error?.response?.data?.error, {
                position: 'bottom-center'
            });
        }
    };

    const usersColumns = [
        {
            Header: 'FirstName',
            accessor: 'firstName'
        },
        {
            Header: 'Last Name',
            accessor: 'lastName'
        },
        {
            Header: 'Email',
            accessor: 'email'
        },
        {
            Header: 'Type',
            accessor: 'type'
        },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ value, row }) => (
                <div onClick={e => switchAccountStatus(e, row.original)}>{value}</div>
            )
        }
    ];

    const navigate = useNavigate();

    const fetchAndSetData = async () => {
        const { data } = await API.getAllUsers();

        setUsers(data);
    };

    useEffect(() => {
        fetchAndSetData();
    }, []);

    const usersToShow = useMemo(() => {
        if (!users) return;
        const _search = search.toLowerCase();
        return users.filter(
            u =>
                u.firstName?.toLowerCase().startsWith(_search) ||
                u.lastName?.toLowerCase().startsWith(_search) ||
                u.email?.toLowerCase().startsWith(_search) ||
                u.type?.toLowerCase().startsWith(_search)
        );
    }, [search, users]);

    const onUserClick = userClicked => {
        if (userClicked.type === 'Coach') {
            navigate(`/coach/${userClicked._id}`);
        } else if (userClicked.type === 'Athlete') {
            navigate(`/athlete/${userClicked._id}`);
        }
    };

    if (!users) return null;

    const createUserInviteModal = () => {
        if (!inviteUserModal) return null;

        return (
            <Modal
                closeCB={() => setInviteUserModal(false)}
                title={'Invite User'}
                children={
                    <InviteUserModal
                        onClose={newUser => {
                            setUsers([newUser, ...users]);
                            setInviteUserModal(false);
                        }}
                    />
                }
            />
        );
    };

    return (
        <div className={styles.pageWrap}>
            <ToastContainer />
            <div className={styles.top}>
                <h3>Users</h3>
                <div className='btn btn-primary' onClick={() => setInviteUserModal(true)}>
                    Add User
                </div>
            </div>
            <input
                type='search'
                onChange={u => setSearch(u.target.value)}
                placeholder='Search...'
                className='island'
                style={{ border: 0, padding: '5px' }}
            ></input>
            <div className={`${styles.userTableWrap} island`}>
                <Table columns={usersColumns} data={usersToShow} onRowClick={onUserClick} />
            </div>
            {createUserInviteModal()}
        </div>
    );
}
