import { useUser } from '../../../Context/useUser';
import API from '../../../Utils/api';

import styles from './MyCoachesPage.module.css';
import { ToastContainer, toast } from 'react-toastify';

export default function EditCoachModal({ onClose, relationship }) {
    const { user } = useUser();
    const acceptCoach = async () => {
        try {
            await API.approveRelationshipById(relationship._id);
            onClose?.();
            toast.success('OK', {
                position: 'bottom-center'
            });
        } catch (error) {
            toast.error(error?.response?.data?.error, {
                position: 'bottom-center'
            });
        }
    };
    const rejectCoach = async () => {
        try {
            await API.deleteRelationshipById(relationship._id);
            onClose?.();
            toast.success('OK', {
                position: 'bottom-center'
            });
        } catch (error) {
            toast.error(error?.response?.data?.error, {
                position: 'bottom-center'
            });
        }
    };

    if (relationship.status === 'Pending') {
        return (
            <div className={styles.coachesModalWrap}>
                <ToastContainer />
                <div>
                    Coach: {relationship.coach.firstName} {relationship.coach.lastName}
                </div>
                <div>Status: Pending</div>
                <div>Invited: {new Date(relationship.createdAt).toLocaleDateString()}</div>
                <div>
                    <button className='btn btn-danger' onClick={rejectCoach}>
                        Remove
                    </button>
                    {relationship.createdBy !== user._id && (
                        <button className='btn btn-success' onClick={acceptCoach}>
                            Accept
                        </button>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className={styles.coachesModalWrap}>
            <ToastContainer />
            <div className={styles.coachesModalWrap}>
                <div>
                    Coach: {relationship.coach.firstName} {relationship.coach.lastName}
                </div>
                <div>
                    Coaching you since: {new Date(relationship.updatedAt).toLocaleDateString()}
                </div>
                <div>
                    <button className='btn btn-danger' onClick={rejectCoach}>
                        Remove coach
                    </button>
                </div>
            </div>
        </div>
    );
}
