import React, { createContext, useContext, useEffect, useRef, useState } from 'react';

import API, { setAuthToken } from '../Utils/api';

export const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export function UserProvider({ children }) {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const setTimeoutId = useRef();

    const setRefreshTimeout = () => {
        setTimeoutId.current = setTimeout(refreshToken, 5 * 1000 * 60);
    };

    const refreshToken = async onSuccess => {
        try {
            const res = await API.refreshToken();

            const { token: token_, ...user_ } = res.data;

            onSuccess && onSuccess(user_);

            setAuthToken(token_);
            setRefreshTimeout();
        } catch (err) {
            setLoading(false);
        }
    };

    useEffect(() => {
        refreshToken(user => {
            setUser(user);
            loading && setLoading(false);
        });

        return () => {
            if (setTimeoutId.current !== undefined) clearTimeout(setTimeoutId.current);
        };

        // eslint-disable-next-line
    }, []);

    if (loading) return null;
    user && console.log(user);
    return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>;
}
