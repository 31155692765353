import { useForm } from 'react-hook-form';
import API from '../../../Utils/api';

import styles from './UserSearchPage.module.css';
import { ToastContainer, toast } from 'react-toastify';

export default function InviteUserModal ({ onClose }) {
    const { register, handleSubmit } = useForm({});

    const addUser = async ({ email, firstName, lastName, type }) => {
        try {
            const { data } = await API.createAccount({ email, firstName, lastName, type });
            console.log(data);
            onClose?.({ _id: data.id, email, firstName, lastName, type, status: 'Inactive' });
            toast.success('OK', {
                position: 'bottom-center'
            });
        } catch (error) {
            toast.error(error?.response?.data?.error, {
                position: 'bottom-center'
            });
        }
    };

    return (
        <div className={styles.coachesModalWrap}>
            <ToastContainer />
            <form className='signup'>
                <div className='form-group'>
                    <label htmlFor='email'>Email</label>
                    <input
                        type='text'
                        placeholder='Email Address'
                        className='form-control'
                        {...register('email', { required: true })}
                    />
                </div>
                <div className='form-group'>
                    <label htmlFor='firstName'>First Name</label>
                    <input
                        type='text'
                        placeholder='First Name'
                        className='form-control'
                        {...register('firstName', { required: true })}
                    />
                </div>
                <div className='form-group'>
                    <label htmlFor='lastName'>Last Name</label>
                    <input
                        type='text'
                        placeholder='Last Name'
                        className='form-control'
                        {...register('lastName', { required: true })}
                    />
                </div>
                <div className='form-group'>
                    <label htmlFor='type'>Account type</label>
                    <select {...register('type', { required: true })} className='form-control'>
                        <option value={'Athlete'}>Athlete</option>
                        <option value={'Coach'}>Coach</option>
                        <option value={'Admin'}>Admin</option>
                    </select>
                </div>
            </form>
            <button className='btn btn-primary' onClick={handleSubmit(addUser)}>
                Add User
            </button>
        </div>
    );
}
