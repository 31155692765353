import API from '../../../Utils/api';

import styles from './AthleteSearchPage.module.css';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { useUser } from '../../../Context/useUser';

export default function InfoAthleteModal({ onClose, relationship }) {
    const navigate = useNavigate();

    const { user } = useUser();

    const acceptAthlete = async () => {
        try {
            await API.approveRelationshipById(relationship._id);
            onClose?.();
            toast.success('OK', {
                position: 'bottom-center'
            });
        } catch (error) {
            toast.error(error?.response?.data?.error, {
                position: 'bottom-center'
            });
        }
    };

    const removeAthlete = async () => {
        try {
            await API.deleteRelationshipById(relationship._id);
            onClose?.();
            toast.success('OK', {
                position: 'bottom-center'
            });
        } catch (error) {
            toast.error(error?.response?.data?.error, {
                position: 'bottom-center'
            });
        }
    };

    return (
        <div className={styles.athleteModalWrap}>
            <ToastContainer />
            <div>
                Athlete: {relationship.athlete.firstName} {relationship.athlete.lastName}
            </div>
            <div>Coaching since: {new Date(relationship.updatedAt).toLocaleDateString()}</div>
            <div className='form-row'>
                <button className='btn btn-danger' onClick={removeAthlete}>
                    Remove Athlete
                </button>
                {relationship.status === 'Active' ? (
                    <button
                        className='btn btn-primary'
                        onClick={() => navigate(`/athlete/${relationship.athlete._id}`)}
                    >
                        View Athlete
                    </button>
                ) : relationship.createdBy !== user._id ? (
                    <button className='btn btn-primary' onClick={() => acceptAthlete()}>
                        Accept Athlete
                    </button>
                ) : (
                    ''
                )}
            </div>
        </div>
    );
}
