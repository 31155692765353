import { useForm } from 'react-hook-form';
import API from '../../../Utils/api';

import styles from './MyCoachesPage.module.css';
import { ToastContainer, toast } from 'react-toastify';

export default function InviteCoachModal({ onClose, userId }) {
    const { register, handleSubmit } = useForm();

    const addCoach = async ({ email }) => {
        try {
            await API.requestToCoach(email, userId);
            onClose?.();
            toast.success('OK', {
                position: 'bottom-center'
            });
        } catch (error) {
            toast.error(error?.response?.data?.error, {
                position: 'bottom-center'
            });
        }
    };

    return (
        <div className={styles.coachesModalWrap}>
            <ToastContainer />
            <form className={styles.form}>
                <div className='form-group'>
                    <label htmlFor='email'>Coach's Email</label>
                    <input {...register('email')} className='form-control' />
                </div>
            </form>
            <button className='btn btn-primary' onClick={handleSubmit(addCoach)}>
                Add Coach
            </button>
        </div>
    );
}
