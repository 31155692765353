import { useEffect, useMemo, useState } from 'react';
import API from '../../../Utils/api';

import styles from './MyCoachesPage.module.css';
import UserPreview from '../../../Components/UserPreview/UserPreview';

import { FaCheck, FaRegClock } from 'react-icons/fa6';
import Modal from '../../../Components/Modal/Modal';
import InviteCoachModal from './InviteCoachModal';
import EditCoachModal from './EditCoachModal';
import { useUser } from '../../../Context/useUser';
import { useParams } from 'react-router';
import { useVisitingUser } from '../../../Hooks/useVisitingUser';

export default function MyCoachesPage() {
    const { user } = useUser();

    const { athleteId } = useParams();

    const visitedUser = useVisitingUser(athleteId);

    const currentUser = athleteId ? visitedUser : user;

    const [relationships, setRelationships] = useState(null);

    const [inviteCoachModal, setInviteCoachModal] = useState(null);
    const [editCoachModal, setEditCoachModal] = useState(null);

    const fetchAndSetCoaches = async currUser => {
        if (!currUser) return;
        const { data: relationships } = await API.getCoachesById(currUser._id);
        setRelationships(relationships.reverse());
    };

    useEffect(() => {
        fetchAndSetCoaches(currentUser);
    }, [currentUser]);

    const coachesIslands = useMemo(() => {
        return relationships?.map(relationship => (
            <div
                key={relationship._id}
                className={styles.coachesPreview + ' island'}
                onClick={() => setEditCoachModal(relationship)}
            >
                <UserPreview
                    user={relationship.coach}
                    statusIcon={
                        relationship.status === 'Active' ? (
                            <FaCheck color={'Green'} size={24} />
                        ) : (
                            <FaRegClock color={'Orange'} size={24} />
                        )
                    }
                    excludeCSS={true}
                />
            </div>
        ));
    }, [relationships]);

    const createUserInviteModal = () => {
        if (!inviteCoachModal) return null;

        return (
            <Modal
                closeCB={() => setInviteCoachModal(false)}
                title={'Invite Coach'}
                children={
                    <InviteCoachModal
                        userId={currentUser._id}
                        onClose={() => {
                            setRelationships([]);
                            fetchAndSetCoaches(currentUser);
                            setInviteCoachModal(false);
                        }}
                    />
                }
            />
        );
    };

    const createEditCoachModal = () => {
        if (!editCoachModal) return null;

        return (
            <Modal
                closeCB={() => setEditCoachModal(false)}
                title={''}
                children={
                    <EditCoachModal
                        onClose={() => {
                            setRelationships([]);
                            fetchAndSetCoaches(currentUser);
                            setEditCoachModal(false);
                        }}
                        relationship={editCoachModal}
                    />
                }
            />
        );
    };

    if (!relationships) return null;

    return (
        <div className={styles.wrap}>
            <div className={styles.top}>
                <h3>Coaches</h3>
                <div className='btn btn-primary' onClick={() => setInviteCoachModal(true)}>
                    Add Coach
                </div>
            </div>
            <div className={styles.coachesWrap}>{coachesIslands}</div>
            {createUserInviteModal()}
            {createEditCoachModal()}
        </div>
    );
}
