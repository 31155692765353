import { useEffect } from 'react';

import { createPortal } from 'react-dom';

import styles from './Modal.module.css';

import { IoCloseSharp } from 'react-icons/io5';

const modalRoot = document.getElementById('modal');

export default function Modal({ children, closeCB, title, footer }) {
    const el = document.createElement('div');

    useEffect(() => {
        modalRoot.appendChild(el);
        return () => modalRoot.removeChild(el);
    }, [el]);

    const wrapContent = () => {
        return (
            <div className={styles.modalWrap}>
                <div className={`modal-dialog`} role='document'>
                    <div className={`modal-content ${styles.modal}`}>
                        <div className='modal-header'>
                            <h5 className='modal-title'>{title}</h5>
                            <button
                                type='button'
                                className='btn btn-close'
                                aria-label='Close'
                                onClick={closeCB}
                            />
                        </div>
                        <div className='modal-body'>{children}</div>
                        <div className='modal-footer'>{footer}</div>
                    </div>
                </div>
            </div>
        );
    };

    return createPortal(wrapContent(), el);
}
