import { useForm } from 'react-hook-form';
import API from '../../../Utils/api';

import styles from './AthleteSearchPage.module.css';
import { ToastContainer, toast } from 'react-toastify';

export default function CreateAthleteModal({ onClose, coachId }) {
    const { register, handleSubmit } = useForm();

    const createAthlete = async ({ email, firstName, lastName, birthYear }) => {
        try {
            await API.createNewAthleteThroughRelationship(
                {
                    email,
                    firstName,
                    lastName,
                    birthYear
                },
                coachId
            );
            onClose?.();
            toast.success('OK', {
                position: 'bottom-center'
            });
        } catch (error) {
            toast.error(error?.response?.data?.error, {
                position: 'bottom-center'
            });
        }
    };

    return (
        <div className={styles.athleteModalWrap}>
            <ToastContainer />
            <form className={styles.form}>
                <h4>Athlete's Information</h4>
                <div className='form-group'>
                    <label htmlFor='email'>Email</label>
                    <input {...register('email')} className='form-control' />
                </div>
                <div className='form-row'>
                    <div className='form-group'>
                        <label htmlFor='firstName'>First Name *</label>
                        <input
                            {...register('firstName', { required: true })}
                            className='form-control'
                        />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='lastName'>Last Name *</label>
                        <input
                            {...register('lastName', { required: true })}
                            className='form-control'
                        />
                    </div>
                </div>
                <div className='form-group'>
                    <label htmlFor='birthYear'>Birth Year *</label>
                    <input
                        {...register('birthYear', { required: true })}
                        className='form-control'
                    />
                </div>
            </form>
            <button className='btn btn-primary' onClick={handleSubmit(createAthlete)}>
                Create Athlete
            </button>
        </div>
    );
}
