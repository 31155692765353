import { useForm } from 'react-hook-form';
import styles from './ZonesPage.module.css';
import { useState, useMemo, useEffect } from 'react';

import Line from '../../../Components/Charts/Line/Line';
import Utils from '../../../Utils';
import Table from '../../../Components/Table/Table';
import API from '../../../Utils/api';
import { useUser } from '../../../Context/useUser';
import { useParams } from 'react-router';
import { useVisitingUser } from '../../../Hooks/useVisitingUser';

const { red, green, blue } = Utils.randomColorGenerator();

const columns = [
    {
        Header: 'Upload Date',
        accessor: 'submissionData',
        Cell: ({ row }) => <div>{new Date(row.original.createdAt).toLocaleDateString('en-GB')}</div>
    },
    {
        Header: 'CSS',
        accessor: 'css',
        Cell: ({ row }) => <div>{row.original.css && row.original.css.toFixed(3)}</div>
    },
    {
        Header: 'Race Time (sec)',
        accessor: 'raceTime',
        Cell: ({ row }) => <div>{row.original.velocity && row.original.raceTime.toFixed(3)}</div>
    },
    {
        Header: 'Race Distance (m)',
        accessor: 'raceDistance',
        Cell: ({ row }) => (
            <div>{row.original.velocity && row.original.raceDistance.toFixed(3)}</div>
        )
    },
    {
        Header: 'Velocity (m/sec)',
        accessor: 'velocity',
        Cell: ({ row }) => <div>{row.original.velocity && row.original.velocity.toFixed(3)}</div>
    },
    {
        Header: 'Tempo (100m)',
        accessor: 'tempoForAnyDistance100',
        Cell: ({ row }) => (
            <div>
                {row.original.tempoForAnyDistance100 &&
                    row.original.tempoForAnyDistance100.toFixed(3)}
            </div>
        )
    }
];

export default function JonesTestPage() {
    const [testData, setTestData] = useState([]);
    const [currentPreview, setCurrentPreview] = useState(null);

    const { user } = useUser();

    const { athleteId } = useParams();

    const visitedUser = useVisitingUser(athleteId);

    const currentUser = athleteId ? visitedUser : user;

    const { register, handleSubmit, watch, reset } = useForm({});

    const fetchAndSetData = async currentUser => {
        if (!currentUser) return;
        const { data } = await API.getJonesTestsByUserId(currentUser._id);
        setTestData(data.slice(data.length - 5).reverse());
        setCurrentPreview(data[data.length - 1]?._id);
    };

    useEffect(() => {
        fetchAndSetData(currentUser);
    }, [currentUser]);

    const onSubmit = async data => {
        const { data: res } = await API.createJonesTest(currentUser._id, data);
        setTestData([res, ...testData]);
        reset({});
        setCurrentPreview(res._id);
    };

    const toPreview = watch();
    const [currDatasets1, currDatasets2, currLabels] = [
        [
            {
                label: 'Current Test',
                data: [
                    toPreview.distanceA && toPreview.timeA,
                    toPreview.distanceB && toPreview.timeB,
                    toPreview.distanceC && toPreview.timeC
                ].filter(Boolean),
                borderColor: `rgb(${red}, ${green}, ${blue})`,
                backgroundColor: `rgba(${red}, ${green}, ${blue}, 0.5)`
            }
        ],
        [
            {
                label: 'Current Test',
                data: [
                    toPreview.distanceA && toPreview.timeA && toPreview.distanceA / toPreview.timeA,
                    toPreview.distanceB && toPreview.timeB && toPreview.distanceB / toPreview.timeB,
                    toPreview.distanceC && toPreview.timeC && toPreview.distanceC / toPreview.timeC
                ].filter(Boolean),
                borderColor: `rgb(${red}, ${green}, ${blue})`,
                backgroundColor: `rgba(${red}, ${green}, ${blue}, 0.5)`
            }
        ],
        [toPreview.distanceA, toPreview.distanceB, toPreview.distanceC]
    ];

    const [datasets1, datasets2, labels] = useMemo(() => {
        const toPreview = testData.find(record => record._id === currentPreview);
        if (!toPreview) return [];
        const { red, green, blue } = Utils.randomColorGenerator();
        return [
            [
                {
                    label: new Date(toPreview.createdAt).toLocaleDateString(),
                    data: [toPreview.timeA, toPreview.timeB, toPreview.timeC],
                    borderColor: `rgb(${red}, ${green}, ${blue})`,
                    backgroundColor: `rgba(${red}, ${green}, ${blue}, 0.5)`
                }
            ],
            [
                {
                    label: new Date(toPreview.createdAt).toLocaleDateString(),
                    data: [
                        toPreview.distanceA / toPreview.timeA,
                        toPreview.distanceB / toPreview.timeB,
                        toPreview.distanceC / toPreview.timeC
                    ],
                    borderColor: `rgb(${red}, ${green}, ${blue})`,
                    backgroundColor: `rgba(${red}, ${green}, ${blue}, 0.5)`
                }
            ],
            [toPreview.distanceA, toPreview.distanceB, toPreview.distanceC]
        ];
    }, [testData, currentPreview]);

    const handleRaceSelect = data => {
        setCurrentPreview(data._id);
    };

    return (
        <div className={styles.pageWrap}>
            <div className={styles.dataCollectionWrap + ' island'}>
                <form className={styles.form}>
                    <div className='form-row'>
                        <div className='form-group'>
                            <label htmlFor='distanceA'>Distance A *</label>
                            <input
                                {...register('distanceA', { required: true })}
                                className='form-control'
                            />
                        </div>
                        <div className='form-group'>
                            <label htmlFor='timeA'>Time Trial A *</label>
                            <input
                                type='number'
                                step={0.1}
                                {...register('timeA', { required: true })}
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='form-group'>
                            <label htmlFor='distanceB'>Distance B *</label>
                            <input
                                {...register('distanceB', { required: true })}
                                className='form-control'
                            />
                        </div>
                        <div className='form-group'>
                            <label htmlFor='timeB'>Time Trial B *</label>
                            <input
                                type='number'
                                step={0.1}
                                {...register('timeB', { required: true })}
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='form-group'>
                            <label htmlFor='distanceC'>Distance C *</label>
                            <input
                                {...register('distanceC', { required: true })}
                                className='form-control'
                            />
                        </div>
                        <div className='form-group'>
                            <label htmlFor='timeC'>Time Trial C *</label>
                            <input
                                type='number'
                                step={0.1}
                                {...register('timeC', { required: true })}
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className='form-group'>
                        <label htmlFor='raceDistance'>Race Distance *</label>
                        <input
                            type='number'
                            {...register('raceDistance', { required: true })}
                            className='form-control'
                        />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='tempoDistance'>Tempo Distance *</label>
                        <input
                            type='number'
                            {...register('tempoDistance', { required: true })}
                            className='form-control'
                        />
                    </div>
                </form>
                <Line
                    title={'Critical Velocity'}
                    titleY={'Time (sec)'}
                    titleX={'Distance (m)'}
                    datasets={currDatasets1}
                    labels={currLabels}
                />
                <Line
                    title={'Velocity - Time'}
                    titleY={'Velocity (m/sec)'}
                    titleX={'Time (sec)'}
                    datasets={currDatasets2}
                    labels={currLabels}
                />
                <button className='btn btn-primary' onClick={handleSubmit(onSubmit)}>
                    Submit
                </button>
            </div>
            {testData?.length > 0 && (
                <div className={styles.dataVisualizationWrap + ' island'}>
                    <Table
                        columns={columns}
                        data={testData}
                        onRowClick={handleRaceSelect}
                        selected={testData.find(td => td._id === currentPreview)}
                    />
                    <Line
                        title={'Critical Velocity'}
                        titleY={'Time (sec)'}
                        titleX={'Distance (m)'}
                        datasets={datasets1}
                        labels={labels}
                    />
                    <Line
                        title={'Velocity - Time'}
                        titleY={'Velocity (m/sec)'}
                        titleX={'Time (sec)'}
                        datasets={datasets2}
                        labels={labels}
                    />
                </div>
            )}
        </div>
    );
}
