import { useForm } from 'react-hook-form';

import styles from './ProfilePage.module.css';
import { useUser } from '../../../Context/useUser';
import { useEffect, useMemo, useState } from 'react';
import API from '../../../Utils/api';

import Avatar from 'react-avatar';
import { useParams } from 'react-router';
import { useVisitingUser } from '../../../Hooks/useVisitingUser';
import { ToastContainer, toast } from 'react-toastify';

const targetTimeTransform = obj => {
    let times = 0;
    if (obj?.hours && !isNaN(obj?.hours)) times += 3600 * obj.hours;
    if (obj?.minutes && !isNaN(obj?.minutes)) times += 60 * obj.minutes;
    if (obj?.seconds && !isNaN(obj?.seconds)) times += obj.seconds;
    return times;
};

const userTargetTimeTransform = targetTime => {
    if (!targetTime || typeof targetTime !== 'number') return targetTime;

    const obj = {};

    if (targetTime >= 3600) {
        obj.hours = Math.floor(targetTime / 3600);
        targetTime = targetTime % 3600;
    }

    obj.minutes = Math.floor(targetTime / 60);
    obj.seconds = targetTime % 60;

    return obj;
};

export default function ProfilePage() {
    const { user, setUser } = useUser();

    const { athleteId } = useParams();

    const visitedUser = useVisitingUser(athleteId);

    const currentUser = athleteId ? visitedUser : user;

    const [editMode, setEditMode] = useState(false);
    const toggleEditMode = () => {
        setEditMode(prevEditMode => !prevEditMode);
    };

    if (currentUser) currentUser.targetTime = userTargetTimeTransform(currentUser.targetTime);
    const { register, handleSubmit, watch, reset } = useForm({
        defaultValues: currentUser
    });

    const { register: registerName, handleSubmit: handleSubmitName } = useForm({
        defaultValues: currentUser
    });

    useEffect(() => {
        reset(currentUser);
    }, [currentUser]);

    const [height, weight, birthYear, athleteType, distance] = watch([
        'height',
        'weight',
        'birthYear',
        'athleteType',
        'distance'
    ]);

    const age = useMemo(
        () => (birthYear < 1990 ? null : new Date().getFullYear() - birthYear),
        [birthYear]
    );

    const updateUser = async userD => {
        userD.targetTime = targetTimeTransform(userD.targetTime);
        try {
            if (user._id === currentUser._id) {
                const { data } = await API.updateMyself(userD);
                setUser({ ...user, ...data });
            } else {
                const { data } = await API.updateUser(currentUser._id, userD);
                // visitedUser
            }
            setEditMode(false);
            toast.success(`User data were updated successfully!`, {
                position: 'bottom-center'
            });
        } catch {
            toast.error(`Couldn't update user data`, {
                position: 'bottom-center'
            });
        }
    };

    const updateUserName = async data => {
        const d = await API.updateMyself(data);
        console.log(d);
    };

    if (!currentUser) return;

    if (editMode) {
        return (
            <div className={styles.pageWrap}>
                <div style={{ position: 'absolute' }}>
                    <ToastContainer />
                </div>
                <div className={styles.leftSide + ' island'}>
                    <form className={styles.form}>
                        <div className='form-row'>
                            <div className='form-group'>
                                <label htmlFor='firstName'>First Name</label>
                                <input {...register('firstName')} className='form-control' />
                            </div>
                            <div className='form-group'>
                                <label htmlFor='lastName'>Last Name</label>
                                <input {...register('lastName')} className='form-control' />
                            </div>
                        </div>
                    </form>
                    <button className='btn btn-primary' onClick={toggleEditMode}>
                        {editMode ? 'View Profile' : 'Edit Profile'}
                    </button>
                </div>
                <div className={styles.rightSide + ' island'}>
                    <form className={styles.form}>
                        <div className='form-row'>
                            <div className='form-group'>
                                <label htmlFor='gender'>Gender</label>
                                <select className='form-control' {...register('gender')}>
                                    <option value={null}></option>
                                    <option value={'Male'}>Male</option>
                                    <option value={'Female'}>Female</option>
                                    <option value={'Other'}>Other</option>
                                </select>
                            </div>
                            <div className='form-group'>
                                <label htmlFor='birthYear'>Birth Year</label>
                                <input
                                    type='number'
                                    min={0}
                                    {...register('birthYear', {
                                        min: 0,
                                        max: new Date().getFullYear()
                                    })}
                                    className='form-control'
                                />
                            </div>
                        </div>
                        <div className='form-row'>
                            <div className='form-group'>
                                <label htmlFor='height'>Height (cm)</label>
                                <input
                                    type='number'
                                    min={0}
                                    {...register('height')}
                                    className='form-control'
                                />
                            </div>
                            <div className='form-group'>
                                <label htmlFor='weight'>Weight (kg)</label>
                                <input
                                    type='number'
                                    min={0}
                                    {...register('weight')}
                                    className='form-control'
                                />
                            </div>
                            <div className='form-group'>
                                <label htmlFor='sittingHeight'>Sitting Height (cm)</label>
                                <input
                                    type='number'
                                    min={0}
                                    {...register('sittingHeight')}
                                    className='form-control'
                                />
                            </div>
                            <div className='form-group'>
                                <label htmlFor='handOpening'>Hand opening (cm)</label>
                                <input
                                    type='number'
                                    min={0}
                                    {...register('handOpening')}
                                    className='form-control'
                                />
                            </div>
                        </div>

                        <div className='form-row'>
                            <div className='form-group'>
                                <label htmlFor='bmp'>BMP (Basic Metabolic Panel)</label>
                                <input
                                    type='number'
                                    disabled={true}
                                    defaultValue={
                                        weight && weight !== '' && height && height !== '' && age
                                            ? (10 * weight + 6.25 * height - 5 * age + 5).toFixed(2)
                                            : null
                                    }
                                    {...register('bmp')}
                                    className='form-control'
                                />
                            </div>
                            <div className='form-group'>
                                <label htmlFor='bmi'>BMI (Body Mass Index)</label>
                                <input
                                    type='number'
                                    disabled={true}
                                    defaultValue={
                                        weight &&
                                        weight !== '' &&
                                        height &&
                                        height !== '' &&
                                        height !== '0'
                                            ? (weight / ((height / 100) * (height / 100))).toFixed(
                                                  2
                                              )
                                            : null
                                    }
                                    {...register('bmi')}
                                    className='form-control'
                                />
                            </div>
                            <div className='form-group'>
                                <label htmlFor='hrMax'>Hr max</label>
                                <input
                                    type='number'
                                    disabled={true}
                                    defaultValue={age ? 220 - age : null}
                                    {...register('hrMax')}
                                    className='form-control'
                                />
                            </div>
                        </div>
                        <div className='form-row'>
                            <div className='form-group'>
                                <label htmlFor='athleteLevel'>Athlete Level</label>
                                <select className='form-control' {...register('athleteLevel')}>
                                    <option value={null}></option>
                                    <option value={'Young'}>Young</option>
                                    <option value={'Adolescent'}>Adolescent</option>
                                    <option value={'Elite'}>Elite</option>
                                    <option value={'Master'}>Master</option>
                                </select>
                            </div>
                        </div>
                        <div className='form-row'>
                            <div className='form-group'>
                                <label htmlFor='athleteType'>Athlete Type</label>
                                <select className='form-control' {...register('athleteType')}>
                                    <option value={undefined}></option>
                                    <option value={'Pool'}>Pool</option>
                                    <option value={'Open Water'}>Open Water</option>
                                </select>
                            </div>
                        </div>
                        {athleteType === 'Pool' && (
                            <>
                                <h4>Favorite Race</h4>
                                <div className='form-row'>
                                    <div className='form-group'>
                                        <label htmlFor='preferredDistance'>Distance</label>
                                        <select
                                            className='form-control'
                                            {...register('preferredDistance')}
                                        >
                                            <option value={null}></option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                            <option value={200}>200</option>
                                            <option value={400}>400</option>
                                            <option value={800}>800</option>
                                            <option value={1500}>1500</option>
                                        </select>
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor='preferredStyle'>Style</label>
                                        <select
                                            className='form-control'
                                            {...register('preferredStyle')}
                                        >
                                            <option value={null}></option>
                                            <option value={'Freestyle'}>Freestyle</option>
                                            {distance !== '800' && distance !== '1500' && (
                                                <>
                                                    <option value={'Backstroke'}>Backstroke</option>
                                                    <option value={'BreastStroke'}>
                                                        Breaststroke
                                                    </option>
                                                    <option value={'Butterfly'}>Butterfly</option>
                                                    <option value={'Individual Medley'}>
                                                        Individual Medley
                                                    </option>
                                                </>
                                            )}
                                        </select>
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor='targetTime'>Target Time</label>
                                        <div style={{ display: 'flex', gap: '10px' }}>
                                            <input
                                                type='number'
                                                min={0}
                                                placeholder='minutes'
                                                {...register('targetTime.minutes', {
                                                    valueAsNumber: true
                                                })}
                                                className='form-control'
                                            />
                                            <input
                                                type='number'
                                                min={0}
                                                placeholder='seconds'
                                                {...register('targetTime.seconds', {
                                                    valueAsNumber: true
                                                })}
                                                className='form-control'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        {athleteType === 'Open Water' && (
                            <>
                                <h4>Favorite Race</h4>
                                <div className='form-row'>
                                    <div className='form-group'>
                                        <label htmlFor='distance'>Distance</label>
                                        <select className='form-control' {...register('distance')}>
                                            <option value={null}></option>
                                            <option value={750}>750</option>
                                            <option value={1500}>1500</option>
                                            <option value={3000}>3000</option>
                                            <option value={5000}>5000</option>
                                            <option value={10000}>10000</option>
                                            <option value={30000}>30000</option>
                                        </select>
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor='targetTime'>Target Time</label>
                                        <div style={{ display: 'flex', gap: '10px' }}>
                                            <input
                                                type='number'
                                                min={0}
                                                placeholder='hours'
                                                {...register('targetTime.hours', {
                                                    valueAsNumber: true
                                                })}
                                                className='form-control'
                                            />
                                            <input
                                                type='number'
                                                min={0}
                                                placeholder='minutes'
                                                {...register('targetTime.minutes', {
                                                    valueAsNumber: true
                                                })}
                                                className='form-control'
                                            />
                                            <input
                                                type='number'
                                                min={0}
                                                placeholder='seconds'
                                                {...register('targetTime.seconds', {
                                                    valueAsNumber: true
                                                })}
                                                className='form-control'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        <button className='btn btn-primary' onClick={handleSubmit(updateUser)}>
                            Update
                        </button>
                    </form>
                </div>
            </div>
        );
    }

    const bmp =
        currentUser.weight &&
        currentUser.weight !== '' &&
        currentUser.height &&
        currentUser.height !== '' &&
        currentUser.birthYear
            ? (
                  10 * currentUser.weight +
                  6.25 * currentUser.height -
                  5 *
                      (currentUser.birthYear < 1990
                          ? null
                          : new Date().getFullYear() - currentUser.birthYear) +
                  5
              ).toFixed(2)
            : null;

    const bmi =
        currentUser.weight &&
        currentUser.weight !== '' &&
        currentUser.height &&
        currentUser.height !== '' &&
        currentUser.height !== '0'
            ? (
                  currentUser.weight /
                  ((currentUser.height / 100) * (currentUser.height / 100))
              ).toFixed(2)
            : null;

    const hrMax =
        220 -
        (currentUser.birthYear < 1990 ? null : new Date().getFullYear() - currentUser.birthYear);

    return (
        <div className={styles.pageWrap}>
            <div style={{ position: 'absolute' }}>
                <ToastContainer />
            </div>
            <div className={styles.leftSide + ' island'}>
                <Avatar name={`${currentUser.firstName} ${currentUser.lastName}`} round={true} />
                <div className={styles.name}>
                    {currentUser.firstName} {currentUser.lastName}
                </div>
                <button className='btn btn-primary' onClick={toggleEditMode}>
                    {editMode ? 'View Profile' : 'Edit Profile'}
                </button>
                <div className={styles.email}>{currentUser.email}</div>
                <div className={styles.about}>
                    <div className={styles.aboutTitle}>About</div>
                    <div className={styles.aboutCntent}>
                        {currentUser.about || 'No available athlete information'}
                    </div>
                    <div className={styles.aboutCntent} title={currentUser.currentCSS || 'No CSS has been recorded for the user'}>
                        Critical Swim Speed (CSS): {currentUser?.currentCSS?.toFixed(2) === undefined ? 'N/A': currentUser?.currentCSS?.toFixed(2) }
                    </div>
                </div>
            </div>
            <div className={styles.rightSidePreview}>
                <div className={`${styles.rightSidePreviewItem} island`}>
                    <h4>General</h4>
                    <div className={styles.rightSidePreviewItemInner}>
                        Gender: <span>{currentUser.gender || 'Not set'}</span>
                    </div>
                    <div className={styles.rightSidePreviewItemInner}>
                        Birth Year: <span>{currentUser.birthYear || 'Not set'}</span>
                    </div>
                </div>
                <div className={`${styles.rightSidePreviewItem} island`}>
                    <h4>Biometrics</h4>
                    <div className={styles.rightSidePreviewItemInner}>
                        Height (cm): <span>{currentUser.height || 'Not set'}</span>
                    </div>
                    <div className={styles.rightSidePreviewItemInner}>
                        Weight (kg): <span>{currentUser.weight || 'Not set'}</span>
                    </div>
                    <div className={styles.rightSidePreviewItemInner}>
                        Sitting Height (cm): <span>{currentUser.sittingHeight || 'Not set'}</span>
                    </div>
                    <div className={styles.rightSidePreviewItemInner}>
                        Hand opening (cm): <span>{currentUser.handOpening || 'Not set'}</span>
                    </div>
                </div>
                <div className={`${styles.rightSidePreviewItem} island`}>
                    <h4>Calculated</h4>
                    <div className={styles.rightSidePreviewItemInner}>
                        BMP (Basic Metabolic Panel): <span>{bmp || 'Not set'}</span>
                    </div>
                    <div className={styles.rightSidePreviewItemInner}>
                        BMI (Body Mass Index): <span>{bmi || 'Not set'}</span>
                    </div>
                    <div className={styles.rightSidePreviewItemInner}>
                        Hr max: <span>{hrMax || 'Not set'}</span>
                    </div>
                </div>
                <div className={`${styles.rightSidePreviewItem} island`}>
                    <h4>Level</h4>
                    <div className={styles.rightSidePreviewItemInner}>
                        Athlete Level: <span>{currentUser.athleteLevel || 'Not set'}</span>
                    </div>
                    <div className={styles.rightSidePreviewItemInner}>
                        Athlete Type: <span>{currentUser.athleteType || 'Not set'}</span>
                    </div>
                </div>
                {athleteType === 'Pool' && (
                    <div className={`${styles.rightSidePreviewItem} island`}>
                        <h4>Favorite Race</h4>
                        <div className={styles.rightSidePreviewItemInner}>
                            Distance: <span>{currentUser.preferredDistance || 'Not set'}</span>
                        </div>
                        <div className={styles.rightSidePreviewItemInner}>
                            Style: <span>{currentUser.preferredStyle || 'Not set'}</span>
                        </div>
                        <div className={styles.rightSidePreviewItemInner}>
                            Target Time:
                            <span>
                                {currentUser.targetTime
                                    ? `${currentUser.targetTime.minutes
                                          .toString()
                                          .padStart(2, '0')}:${currentUser.targetTime.seconds
                                          .toString()
                                          .padStart(2, '0')}`
                                    : 'Not set'}
                            </span>
                        </div>
                    </div>
                )}
                {athleteType === 'Open Water' && (
                    <div className={`${styles.rightSidePreviewItem} island`}>
                        <h4>Favorite Race</h4>
                        <div className={styles.rightSidePreviewItemInner}>
                            Distance: <span>{currentUser.preferredDistance || 'Not set'}</span>
                        </div>
                        <div className={styles.rightSidePreviewItemInner}>
                            Style: <span>{currentUser.preferredStyle || 'Not set'}</span>
                        </div>
                        <div className={styles.rightSidePreviewItemInner}>
                            Target Time:
                            <span>
                                {currentUser.targetTime
                                    ? `${(currentUser.targetTime.hours || 0)
                                          .toString()
                                          .padStart(2, '0')}:${currentUser.targetTime.minutes
                                          .toString()
                                          .padStart(2, '0')}:${currentUser.targetTime.seconds
                                          .toString()
                                          .padStart(2, '0')}`
                                    : 'Not set'}
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
