import { Outlet, useParams } from 'react-router';

import styles from './CoachPageLayout.module.css';

import CoachPages from './CoachPages.json';
import AthletePages from '../AthletePageLayout/AthletePages.json';

import Sidebar from '../../Components/Sidebar/Sidebar';

import water from './water.jpg';
import { useVisitingUser } from '../../Hooks/useVisitingUser';

export default function CoachPageLayout() {
    const { athleteId } = useParams();

    const visitedUser = useVisitingUser(athleteId);

    const pages = [
        ...CoachPages,
        athleteId &&
            visitedUser && {
                alias: (
                    <div
                        style={{ height: '1px', width: '100%', border: '1px solid #45a7f7' }}
                    ></div>
                )
            },
        athleteId &&
            visitedUser && {
                alias: (
                    <div style={{ width: '100%', fontWeight: 'bold', textAlign: 'center' }}>
                        {visitedUser.firstName} {visitedUser.lastName}
                    </div>
                )
            },
        ...(athleteId
            ? AthletePages.filter(page => !page.onlyAthlete).map(page => ({
                  ...page,
                  path: `athlete/${athleteId}/${page.path}`
              }))
            : [])
    ].filter(Boolean);

    return (
        <div
            className={styles.coachPagesWrap}
            style={{
                background: `linear-gradient(rgba(255, 255, 255, 0.8), rgb(8, 72, 142)), url(${water})`,
                backgroundSize: 'cover'
            }}
        >
            <Sidebar pages={pages} />
            <div className={styles.coachContent}>
                <Outlet />
            </div>
        </div>
    );
}
