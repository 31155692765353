import { Outlet, useParams } from 'react-router';

import styles from './AdminPageLayout.module.css';

import AdminPages from './AdminPages.json';
import AthletePages from '../AthletePageLayout/AthletePages.json';
import CoachPages from '../CoachPageLayout/CoachPages.json';

import Sidebar from '../../Components/Sidebar/Sidebar';

import water from './water.jpg';
import { useVisitingUser } from '../../Hooks/useVisitingUser';

export default function AdminPageLayout () {
    const { athleteId, coachId } = useParams();

    const visitedUser = useVisitingUser(athleteId || coachId);

    const pages = [...AdminPages];

    if ((athleteId || coachId) && visitedUser) {
        pages.push(
            {
                alias: (
                    <div
                        style={{ height: '1px', width: '100%', border: '1px solid #45a7f7' }}
                    ></div>
                )
            },
            {
                alias: (
                    <div style={{ width: '100%', fontWeight: 'bold', textAlign: 'center' }}>
                        {visitedUser.firstName} {visitedUser.lastName}
                    </div>
                )
            }
        );
    }

    if (athleteId) {
        pages.push(...AthletePages.map(page => ({
            ...page,
            path: `athlete/${athleteId}/${page.path}`
        })));
    }
    if (coachId) {
        pages.push(...CoachPages.map(page => ({
            ...page,
            path: `coach/${coachId}/${page.path}`
        })));
    }

    return (
        <div
            className={styles.adminPagesWrap}
            style={{
                background: `linear-gradient(rgba(255, 255, 255, 0.8), rgb(8, 72, 142)), url(${water})`,
                backgroundSize: 'cover'
            }}
        >
            <Sidebar pages={pages} />
            <div className={styles.adminContent}>
                <Outlet />
            </div>
        </div>
    );
}
