import { useEffect, useState } from 'react';
import API from '../../../Utils/api';

import styles from './FeedbackInspection.module.css';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';

const Feedback = ({ data: feedback }) => {
    
    const { register, handleSubmit } = useForm({
        defaultValues: feedback
    });

    const onChange = async ({ status }) => {
        try {
            await API.updateFeedback({ feedbackId: feedback._id, status });
            toast.success('Feedback State Updated', {
                position: 'bottom-center'
            });
        } catch (error) {
            toast.error('Failed to Update Feedback Status', {
                position: 'bottom-center'
            });
        }
    };

    return (
        <div className={`${styles.feedback} island`}>
            <div style={{ position: 'absolute' }}>
                <ToastContainer />
            </div>
            <div className={styles.feedbackTitle}>
                <b>Subject: {feedback.subject}</b>
                <div className={`form-group ${styles.feedbackStatus}`}>
                    <label htmlFor='status'>Status</label>
                    <select className='form-control' {...register('status')}>
                        <option value={'Open'}>Open</option>
                        <option value={'Resolved'}>Resolved</option>
                        <option value={'Acknowledged'}>Acknowledged</option>
                    </select>
                </div>
            </div>
            <div>Message: {feedback.message}</div>
            <div>
                Submitted by: {feedback.name} ({feedback.email})
            </div>
            <div>Submitted at: {new Date(feedback.date).toLocaleDateString()}</div>
            <div>Type: {feedback.type}</div>
            <button className='btn btn-primary' onClick={handleSubmit(onChange)}>
                Update
            </button>
        </div>
    );
};

export default function FeedbackInspection() {
    const [feedbacks, setFeedbacks] = useState(null);

    const fetchAndSetFeedbacks = async () => {
        const { data: fb } = await API.getFeedbacks();
        setFeedbacks(fb);
    };

    useEffect(() => {
        fetchAndSetFeedbacks();
    }, []);

    if (!feedbacks) return null;

    return (
        <div className={styles.feedbackWrap}>
            {feedbacks.map(fb => (
                <Feedback key={fb._id} data={fb} />
            ))}
        </div>
    );
}
