import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from 'chart.js';

import { useMemo } from 'react';
import { Chart } from 'react-chartjs-2';

ChartJS.register(
    BarController,
    BarElement,
    CategoryScale,
    LinearScale,
    Legend,
);

export default function MixedBarLines ({ data, options }) {
    const { datasets, labels } = data;

    const chartData = useMemo(() => ({
        labels: labels,
        datasets: datasets,

    }), [datasets, labels]);

    return (
        <Chart type='bar' data={chartData} options={options} />
    )
}
