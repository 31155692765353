import React, { useEffect } from 'react';
import { Table as Table_ } from 'react-bootstrap';
import { useTable, usePagination } from 'react-table';

import styles from './Table.module.css';

export default function Table ({
    columns,
    data,
    onRowClick,
    selected,
    className,
    rowsPerPage: _rowsPerPage,
    pageSkipping = false,
    updatePageSize = false
}) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = useTable(
        {
            columns,
            data
        },
        usePagination
    );

    return (
        <Table_ bordered {...getTableProps()} className={className}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => {
                            const { render, getHeaderProps } = column;
                            return <th {...getHeaderProps()}>{render('Header')}</th>;
                        })}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                    prepareRow(row);
                    const isHighlighted = row.original === selected;
                    return (
                        <tr
                            {...row.getRowProps()}
                            onClick={() => onRowClick && onRowClick(row.original)}
                            className={`${styles.row} ${isHighlighted ? styles.selected : ''}`}
                        >
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                            })}
                        </tr>
                    );
                })}
            </tbody>
            {pageOptions.length > 1 && (
                // <tfoot>
                <div className={styles.footer}>
                    <div className={styles.pagination}>
                        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                            {'<<'}
                        </button>
                        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                            {'<'}
                        </button>
                        <span className={styles.paginationInfo}>
                            Page:
                            <strong>
                                {pageIndex + 1} of {pageOptions.length}
                            </strong>
                        </span>
                        <button onClick={() => nextPage()} disabled={!canNextPage}>
                            {'>'}
                        </button>
                        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                            {'>>'}
                        </button>
                        {pageSkipping && (
                            <span>
                                | Go to page:
                                <input
                                    type='number'
                                    defaultValue={pageIndex + 1}
                                    onChange={e => {
                                        const page = e.target.value
                                            ? Number(e.target.value) - 1
                                            : 0;
                                        gotoPage(page);
                                    }}
                                    style={{ width: '100px' }}
                                />
                            </span>
                        )}
                        {updatePageSize && (
                            <select
                                value={pageSize}
                                onChange={e => {
                                    setPageSize(Number(e.target.value));
                                }}
                            >
                                {[10, 20, 30, 40, 50].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                    </option>
                                ))}
                            </select>
                        )}
                    </div>
                </div>
                // </tfoot>
            )}
        </Table_>
    );
}
