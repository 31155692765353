import LandingPage from './Pages/LandingPage/LandingPage';
import BlogPage from './Pages/BlogPage/BlogPage';

import PublicPageLayout from './Layouts/PublicPageLayout/PublicPageLayout';
import LoginPage from './Pages/LoginPage/LoginPage';
import ForgotPasswordPage from './Pages/ForgotPasswordPage/ForgotPasswordPage';
import RecoverPasswordPage from './Pages/RecoverPasswordPage/RecoverPasswordPage';

export default function PublicRoutes() {
    return [
        {
            path: '/',
            page: <LandingPage />
        },
        {
            path: '/',
            page: <PublicPageLayout />,
            children: [
                {
                    path: '/blog',
                    page: <BlogPage />
                },
                {
                    path: '/login',
                    page: <LoginPage />
                },
                {
                    path: '/forgot-password',
                    page: <ForgotPasswordPage />
                },
                {
                    path: '/reset-password/:resetPasswordId',
                    page: <RecoverPasswordPage />
                }
            ]
        }
    ];
}
