import { useForm } from 'react-hook-form';
import styles from './ZonesPage.module.css';
import { useEffect, useMemo, useState } from 'react';
import Line from '../../../Components/Charts/Line/Line';
import Utils from '../../../Utils';
import Table from '../../../Components/Table/Table';
import API from '../../../Utils/api';
import { useUser } from '../../../Context/useUser';
import { useVisitingUser } from '../../../Hooks/useVisitingUser';
import { useParams } from 'react-router';

const columns = [
    {
        Header: 'Upload Date',
        accessor: 'submissionData',
        Cell: ({ row }) => <div>{new Date(row.original.createdAt).toLocaleDateString('en-GB')}</div>
    },
    {
        Header: 'CSS',
        accessor: 'css',
        Cell: ({ row }) => <div>{row.original.css.toFixed(2)}</div>
    },
    {
        Header: 'Time Average',
        accessor: 'timeAverage',
        Cell: ({ row }) => (
            <div>
                {(row.original.time50 +
                    row.original.time100 +
                    row.original.time200 +
                    row.original.time400) /
                    4}
            </div>
        )
    }
];

const columnsZones = [
    {
        Header: 'Zone',
        accessor: 'title'
    },
    {
        Header: 'Velocity (m/sec)',
        columns: [
            {
                Header: 'From',
                accessor: 'velocityFrom'
            },
            {
                Header: 'To',
                accessor: 'velocityTo'
            }
        ]
    },
    {
        Header: 'Time (sec)',
        columns: [
            {
                Header: 'From',
                accessor: 'timeFrom'
            },
            {
                Header: 'To',
                accessor: 'timeTo'
            }
        ]
    }
];

const { red, green, blue } = Utils.randomColorGenerator();

export default function MeterTestPage() {
    const [testData, setTestData] = useState([]);
    const [currentPreview, setCurrentPreview] = useState(null);

    const [velocity, setVelocity] = useState(50);

    const { user } = useUser();

    const { athleteId } = useParams();

    const visitedUser = useVisitingUser(athleteId);

    const currentUser = athleteId ? visitedUser : user;

    const { register, handleSubmit, watch, reset } = useForm({
        defaultValues: {
            meters50: 50,
            meters100: 100,
            meters200: 200,
            meters400: 400
        }
    });

    const fetchAndSetTestData = async currentUser => {
        if (!currentUser) return;
        const { data: tests } = await API.getTest50TestsByUserId(currentUser._id);
        setTestData(tests.slice(tests.length - 5).reverse());
        setCurrentPreview(tests[tests.length - 1]);
    };

    useEffect(() => {
        fetchAndSetTestData(currentUser);
    }, [currentUser]);

    useEffect(() => {
        if (currentPreview !== null) {
            const cssValue = Number.parseFloat(currentPreview?.css);
            if (!isNaN(cssValue)) {
                const fetchVelocityZones = async () => {
                    try {
                        const { data: res } = await API.velocityZones(cssValue, 50);
                        setVelocityZones(res);
                    } catch (error) {
                        console.error("Error fetching velocity zones:", error);
                    }
                };
                fetchVelocityZones();
            } else {
                console.warn("CSS value is NaN, skipping fetchVelocityZones");
            }
        }
    }, [currentPreview]);

    const onSubmit = async ({ time50, time100, time200, time400 }) => {
        const { data: res } = await API.createTest50Test(currentUser._id, {
            time50,
            time100,
            time200,
            time400
        });
        setTestData([res, ...testData]);
        reset({});
        setCurrentPreview(res);
    };

    const [velocityZones, setVelocityZones] = useState([]);

    const handleVelocityChange = async e => {
        const selectedVelocity = parseInt(e.target.value);
        setVelocity(selectedVelocity);

        if (currentPreview) {
            try {
                setVelocityZones((await API.velocityZones(currentPreview?.css, selectedVelocity)).data);
            } catch (error) {
                console.error('Error fetching test data:', error);
            }
        }
    };

    const [datasets, labels, previewingDataset] = useMemo(() => {
        const toPreview = testData.find(record => record._id === currentPreview._id);
        if (!toPreview) return [];
        return [
            [
                {
                    label: new Date(toPreview.createdAt).toLocaleDateString(),
                    data: [
                        toPreview.time50,
                        toPreview.time100,
                        toPreview.time200,
                        toPreview.time400
                    ],
                    borderColor: `rgb(${red}, ${green}, ${blue})`,
                    backgroundColor: `rgba(${red}, ${green}, ${blue}, 0.5)`
                }
            ],
            ['50', '100', '200', '400'],
            toPreview
        ];
    }, [testData, currentPreview]);

    const currentInput = watch();

    const currentDataset = {
        label: 'Current Record',
        data: [
            currentInput.time50,
            currentInput.time100,
            currentInput.time200,
            currentInput.time400
        ],
        borderColor: `rgb(${red}, ${green}, ${blue})`,
        backgroundColor: `rgba(${red}, ${green}, ${blue}, 0.5)`
    };
    const handleTestSelection = async data => {
        setCurrentPreview(data);
        const cssValue = Number.parseFloat(data?.css);
        if (!isNaN(cssValue)) {
            setVelocityZones((await API.velocityZones(cssValue, velocity)).data);
        } else {
            console.warn("CSS value is NaN, skipping setVelocityZones");
        }
    };

    return (
        <div className={styles.pageWrap}>
            <div className={styles.dataCollectionWrap + ' island'}>
                <form className={styles.form}>
                    <div className='form-row'>
                        <div className='form-group'>
                            <label htmlFor='meters50'>Meters (m)</label>
                            <input
                                disabled={true}
                                {...register('meters50', { required: true })}
                                className='form-control'
                            />
                        </div>
                        <div className='form-group'>
                            <label htmlFor='time50'>Time (sec) *</label>
                            <input
                                type='number'
                                step={0.1}
                                {...register('time50', { required: true, valueAsNumber: true })}
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='form-group'>
                            <input
                                disabled={true}
                                {...register('meters100', { required: true })}
                                className='form-control'
                            />
                        </div>
                        <div className='form-group'>
                            <input
                                type='number'
                                step={0.1}
                                {...register('time100', { required: true, valueAsNumber: true })}
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='form-group'>
                            <input
                                disabled={true}
                                {...register('meters200', { required: true })}
                                className='form-control'
                            />
                        </div>
                        <div className='form-group'>
                            <input
                                type='number'
                                step={0.1}
                                {...register('time200', { required: true, valueAsNumber: true })}
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='form-group'>
                            <input
                                disabled={true}
                                {...register('meters400', { required: true })}
                                className='form-control'
                            />
                        </div>
                        <div className='form-group'>
                            <input
                                type='number'
                                step={0.1}
                                {...register('time400', { required: true, valueAsNumber: true })}
                                className='form-control'
                            />
                        </div>
                    </div>
                </form>
                <Line
                    titleY={'Time (sec)'}
                    titleX={'Distance (m)'}
                    datasets={[currentDataset]}
                    labels={labels}
                />
                <button className='btn btn-primary' onClick={handleSubmit(onSubmit)}>
                    Submit
                </button>
            </div>
            {testData?.length > 0 && (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', width:'100%' }}>
                    <div className={styles.dataVisualizationWrap + ' island'} style={{ width: '100%' }}>
                        <Table
                            columns={columns}
                            data={testData}
                            onRowClick={handleTestSelection}
                            selected={testData.find(td => td._id === currentPreview)}
                        />
                        <Line
                            // title={'test'}
                            titleY={'Time (sec)'}
                            titleX={'Distance (m)'}
                            datasets={datasets}
                            labels={labels}
                        />
                    </div>
                    <div className={styles.dataVisualizationWrap + ' island'} style={{ width: '100%', padding: '10px' }}>
                        <div className={styles.cssContainer}>
                            <div>CSS: {previewingDataset.css.toFixed(2)}</div>
                        </div>
                    </div>
                    <div className={styles.dataVisualizationWrap + ' island'} style={{ width: '100%' }}>
                        <div className={styles.velocityContainer}>
                            <label htmlFor='velocity'>Velocity (m)</label>
                            <input type='number' className='form-control' value={velocity} onChange={handleVelocityChange} />
                        </div>
                        <Table
                            columns={columnsZones}
                            data={Utils.arrayfyObject(velocityZones, {
                                keyToName: 'name'
                            })}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}
