import { useForm } from 'react-hook-form';
import styles from './RecoverPasswordPage.module.css'

import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router';
import API from '../../Utils/api';

export default function RecoverPasswordPage () {

    const { resetPasswordId } = useParams();

    const navigate = useNavigate();

    const { register, handleSubmit } = useForm();


    const onSubmit = async ({ password, verifyPassword }) => {
        try {
            await API.recoverPassword({ recoverPasswordId: resetPasswordId, password });
            if (password !== verifyPassword) throw new Error(`Passwords don't match`);
            toast.success('Password was successfully updated!', {
                position: 'bottom-center'
            });
            navigate('/login');
        } catch (error) {
            toast.error(`Couldn't reset password!`, {
                position: 'bottom-center'
            });
        }
    }

    return <div className={styles.pageWrap}>
        <div className={styles.content}>
            <div className={styles.title}>
                <ToastContainer />
                Reset Password
            </div>
            <form className={styles.form}>
                <div className='form-group'>
                    <input
                        type='password'
                        {...register('password', { required: true })}
                        className='form-control'
                        placeholder='Password'
                    />
                    <input
                        type='password'
                        {...register('verifyPassword', { required: true })}
                        className='form-control'
                        placeholder='Confirm Password'
                    />
                </div>
            </form>
            <button className='btn btn-primary' onClick={handleSubmit(onSubmit)}>Reset Password</button>
        </div>
    </div>
}