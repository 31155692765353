import { useLocation, useNavigate } from 'react-router';
import styles from './Sidebar.module.css';
import { FaRegUser } from 'react-icons/fa';
import { useMemo, useState } from 'react';

import Config from '../../Config.json';

import { FaAngleDoubleLeft, FaRegChartBar, FaRegClipboard } from 'react-icons/fa';
import { SlSpeedometer } from 'react-icons/sl';
import { CiCalendarDate, CiCircleList } from 'react-icons/ci';
import { LuLogOut } from 'react-icons/lu';
import { TbUserSearch } from 'react-icons/tb';
import { MdOutlinePersonSearch } from 'react-icons/md';
import { RiFeedbackLine } from 'react-icons/ri';

import { VscGraphScatter } from 'react-icons/vsc';

import HMYlogoBlue from './assets/transparentlogo.png';
import { useUser } from '../../Context/useUser';
import API from '../../Utils/api';

const ICON_SIZE = '1.4em';

const iconMap = {
    Dashboard: <CiCircleList size={ICON_SIZE} />,
    Profile: <FaRegUser size={ICON_SIZE} />,
    Zones: <SlSpeedometer size={ICON_SIZE} />,
    Calendar: <CiCalendarDate size={ICON_SIZE} />,
    Performance: <FaRegChartBar size={ICON_SIZE} />,
    RaceAnalysis: <VscGraphScatter size={ICON_SIZE} />,
    Macrocycle: <FaRegClipboard size={ICON_SIZE} />,
    Logout: <LuLogOut size={ICON_SIZE} />,
    AthleteSearch: <TbUserSearch size={ICON_SIZE} />,
    Coach: <MdOutlinePersonSearch size={ICON_SIZE} />,
    Feedback: <RiFeedbackLine size={ICON_SIZE} />
};

export default function Sidebar({ pages }) {
    const [isCollapsed, setIsCollapsed] = useState(
        localStorage.getItem('sideBarStatus') === 'collapsed'
    );

    const { setUser } = useUser();

    const navigate = useNavigate();

    const { pathname } = useLocation();

    const logout = async () => {
        await API.logout();
        setUser(null);
        navigate('/');
    };

    const toggleCollapsed = () => {
        setIsCollapsed(isCollapsed => {
            localStorage.setItem('sideBarStatus', !isCollapsed ? 'collapsed' : 'notcollapsed');
            return !isCollapsed;
        });
    };

    const splitted = useMemo(() => pathname.split('/'), [pathname]);
    return (
        <div className={`${styles.navigation} ${isCollapsed ? styles.isCollapsed : ''} island`}>
            <div className={styles.logoWrap} onClick={() => navigate('/')}>
                <img
                    className={styles.logo}
                    src={HMYlogoBlue}
                    alt='logo'
                    style={{ width: isCollapsed ? '100%' : '40%' }}
                />
                {!isCollapsed && <div className={styles.logoText}>{Config.Appname}</div>}
            </div>
            <br />
            <div className={styles.navigationContent}>
                <div className={`${styles.navigationButtons}`}>
                    {pages.map((page, index) => (
                        <button
                            key={index}
                            onClick={() => navigate(page.path)}
                            className={`${styles.navigationButton} ${
                                splitted.includes(page.path) ? styles.active : ''
                            }`}
                        >
                            {iconMap[page.icon]}
                            {!isCollapsed && page.alias}
                        </button>
                    ))}
                </div>
                <div className={styles.navigationBottom}>
                    <div className={styles.burger} onClick={toggleCollapsed}>
                        <FaAngleDoubleLeft size={ICON_SIZE} />
                    </div>
                    <button
                        onClick={() => {
                            navigate('/feedback');
                        }}
                        className={`${styles.navigationButton}`}
                    >
                        {iconMap['Feedback']}
                        {!isCollapsed && 'Feedback'}
                    </button>
                    <button onClick={logout} className={`${styles.navigationButton}`}>
                        {iconMap['Logout']}
                        {!isCollapsed && 'Logout'}
                    </button>
                </div>
            </div>
        </div>
    );
}
