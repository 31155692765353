import { useMemo } from 'react';
import Modal from '../../../Components/Modal/Modal';

import styles from './CalendarPage.module.css';

export default function ShowRecap ({ data, closeCB }) {

    const [duration, volume, stress] = useMemo(() => {
        let duration = 0;
        let stress = 0;
        let volume = 0;

        for (const event of data.events) {
            volume += event.volume;
            duration += event.trainingDurationMin * 60 + event.trainingDurationSec;
            stress += event.tss;
        }
        return [duration, volume, stress];
    }, [data])

    const totalWorkouts = data.events.length;

    return (
        <Modal
            closeCB={closeCB}
            title={'Recap'}
        >
            <div className={styles.recapWrap}>
                <div>
                    Workouts: {data.completed} ({data.scheduled})
                </div>
                <div className={styles.recapDetailsWrap}>
                    <b>Details:</b>
                    <div className={styles.recapDetails}>
                        <div>
                            Volume: {(volume / totalWorkouts)?.toFixed(2)} ({volume})
                        </div>
                        <div>
                            Duration: {(duration / totalWorkouts)?.toFixed(2)} ({duration})
                        </div>
                        <div>
                            Stress: {(stress / totalWorkouts)?.toFixed(2)} ({stress.toFixed(2)})
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
