import { useForm } from 'react-hook-form';
import Utils from '../../../Utils';
import Modal from '../../../Components/Modal/Modal';

import styles from './CalendarPage.module.css';
import API from '../../../Utils/api';

export default function CreateRace({ user, data, closeCB, onSuccess, onFailure }) {
    const { register, handleSubmit } = useForm({
        defaultValues: data
    });
    const date = Utils.dateToSimpleDateFormat(data.start);

    const onEventCreate = async ({ raceDate, raceType, raceLocation }) => {
        try {
            const { data } = await API.createRace(user._id, {
                raceDate: new Date(raceDate).toUTCString(),
                raceType,
                raceLocation
            });
            onSuccess('Race created!', data);
        } catch (err) {
            console.log(err);
            onFailure("Couldn't create race!");
        }
    };

    return (
        <Modal
            closeCB={closeCB}
            title={'Create Race'}
            footer={
                <button className='btn btn-primary' onClick={handleSubmit(onEventCreate)}>
                    Add
                </button>
            }
        >
            <form className={styles.form}>
                <div className='form-group'>
                    <label htmlFor='raceDate'>Race Date</label>
                    <input
                        type='date'
                        {...register('raceDate', { required: true })}
                        className='form-control'
                        placeholder='Start Datetime'
                        defaultValue={date}
                    />
                </div>
                <div className='form-group'>
                    <label htmlFor='raceType'>Race Type</label>
                    <select className='form-control' {...register('raceType', { required: true })}>
                        <option value={'50'}>50</option>
                        <option value={'100'}>100</option>
                        <option value={'200'}>200</option>
                        <option value={'400'}>400</option>
                        <option value={'800'}>800</option>
                        <option value={'1500'}>1500</option>
                    </select>
                </div>
                <div className='form-group'>
                    <label htmlFor='raceLocation'>Location</label>
                    <input
                        type='text'
                        {...register('raceLocation', { required: true })}
                        className='form-control'
                    />
                </div>
            </form>
        </Modal>
    );
}
