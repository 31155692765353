import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import './LoginPage.css';

import API, { setAuthToken } from '../../Utils/api';
import { useUser } from '../../Context/useUser';
import { useNavigate } from 'react-router';

import { ToastContainer, toast } from 'react-toastify';

import styles from './LoginPage.module.css';

export default function LoginPage() {
    const navigate = useNavigate();
    const { register: registerLogin, handleSubmit: handleSubmitLogin } = useForm({});
    const { register: registerRegister, handleSubmit: handleSubmitRegister } = useForm({});
    const { setUser } = useUser();

    const [showingLogin, setShowingLogin] = useState(true);

    const login = async ({ email, password }) => {
        try {
            const { data } = await API.login({ email, password });

            setAuthToken(data.accessToken);
            setUser(data.user);
            navigate('/');
        } catch {
            toast.error('Credentials were not correct!', {
                position: 'bottom-center'
            });
        }
    };

    const register = async ({ email, password, firstName, lastName, type, confirmPassword }) => {
        try {
            await API.signup({ email, password, confirmPassword, firstName, lastName, type });
            setShowingLogin(true);
        } catch {
            toast.error('Credentials were not correct!', {
                position: 'bottom-center'
            });
        }
    };

    return (
        <div className='pageWrapper'>
            <div className='wrapper'>
                <ToastContainer />
                <div className='title-text'>
                    <div className='title login'>Login Form</div>
                    <div className='title signup'>Signup Form</div>
                </div>
                <div className='form-container'>
                    <div className='slide-controls'>
                        <input type='radio' name='slide' id='login' />
                        <input type='radio' name='slide' id='signup' />
                        <label
                            htmlFor='login'
                            className='slide login'
                            onClick={() => setShowingLogin(true)}
                        >
                            Log in
                        </label>
                        <label
                            htmlFor='signup'
                            className='slide signup'
                            onClick={() => setShowingLogin(false)}
                        >
                            Sign up
                        </label>
                        <div className='slider-tab'></div>
                    </div>
                    <div className='form-inner'>
                        {showingLogin ? (
                            <form className='login'>
                                <div className='field'>
                                    <input
                                        type='text'
                                        placeholder='Email Address'
                                        autoComplete='current-email'
                                        {...registerLogin('email', { required: true })}
                                    />
                                </div>
                                <div className='field'>
                                    <input
                                        type='password'
                                        placeholder='Password'
                                        autoComplete='current-password'
                                        {...registerLogin('password', { required: true })}
                                    />
                                </div>
                                <div className='pass-link'>
                                    <input
                                        type='button'
                                        className={styles.forgotPasswordButton}
                                        onClick={() => navigate('/forgot-password')}
                                        value='Forgot password?'
                                    />
                                </div>
                                <div className='field btn'>
                                    <div className='btn-layer'></div>
                                    <button onClick={handleSubmitLogin(login)}>Log in</button>
                                </div>
                                <div className='signup-link'>
                                    Not a member?{' '}
                                    <a onClick={() => setShowingLogin(false)}>Sign up now</a>
                                </div>
                            </form>
                        ) : (
                            <form className='signup'>
                                <div className='field'>
                                    <input
                                        type='text'
                                        placeholder='Email Address'
                                        {...registerRegister('email', { required: true })}
                                    />
                                </div>
                                <div className='field'>
                                    <input
                                        type='password'
                                        placeholder='Password'
                                        autoComplete='new-password'
                                        {...registerRegister('password', { required: true })}
                                    />
                                </div>
                                <div className='field'>
                                    <input
                                        type='password'
                                        placeholder='Confirm password'
                                        autoComplete='confirm-password'
                                        {...registerRegister('confirmPassword', { required: true })}
                                    />
                                </div>
                                <div className='field'>
                                    <input
                                        type='text'
                                        placeholder='First Name'
                                        {...registerRegister('firstName', { required: true })}
                                    />
                                </div>
                                <div className='field'>
                                    <input
                                        type='text'
                                        placeholder='Last Name'
                                        {...registerRegister('lastName', { required: true })}
                                    />
                                </div>
                                <div className='field'>
                                    <select {...registerRegister('type', { required: true })}>
                                        <option value={'Athlete'}>Athlete</option>
                                        <option value={'Coach'}>Coach</option>
                                    </select>
                                </div>
                                <div className='field btn'>
                                    <div className='btn-layer'></div>
                                    <button onClick={handleSubmitRegister(register)}>
                                        Sign up
                                    </button>
                                </div>
                            </form>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
