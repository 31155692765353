import { useForm } from 'react-hook-form';
import styles from './ForgotPasswordPage.module.css'

import { ToastContainer, toast } from 'react-toastify';
import API from '../../Utils/api';

export default function ForgotPasswordPage () {

    const { register, handleSubmit } = useForm();


    const onSubmit = async ({ email }) => {
        try {
            const { data } = await API.forgotPassword({ email });
            console.log(data);
            toast.success('Recovery Email was sent!', {
                position: 'bottom-center'
            });
            alert(`For UAT purposes please follow the provided link as email simulation ${data.url}`)
        } catch (error) {
            toast.error(`Couldn't send a password recovery email!`, {
                position: 'bottom-center'
            });
        }
    }

    return <div className={styles.pageWrap}>
        <div className={styles.content}>
            <div className={styles.title}>
                <ToastContainer />
                Forgot Password
            </div>
            <form className={styles.form}>
                <div className='form-group'>
                    <input
                        {...register('email', { required: true })}
                        className='form-control'
                        placeholder='Email'
                    />
                </div>
            </form>
            <button className='btn btn-primary' onClick={handleSubmit(onSubmit)}>Send Password Recovery Email</button>
        </div>
    </div>
}