import { useForm } from 'react-hook-form';
import Utils from '../../../Utils';
import Modal from '../../../Components/Modal/Modal';

import styles from './CalendarPage.module.css';

import API from '../../../Utils/api';

export default function CreateWorkout({ user, data, closeCB, onSuccess, onFailure }) {
    const { register, handleSubmit } = useForm({
        defaultValues: data
    });

    const { min, max } = Utils.dateToDatetimeFormatRangeDay(data.start);

    const onEventCreate = async ({ workoutStart, workoutEnd }) => {
        try {
            const { data } = await API.addUserWorkout(user._id, {
                workoutStart: new Date(workoutStart).toUTCString(),
                workoutEnd: new Date(workoutEnd).toUTCString()
            });
            onSuccess('Workout created!', data);
        } catch (err) {
            console.log(err);
            onFailure("Couldn't create workout!");
        }
    };

    return (
        <Modal
            closeCB={closeCB}
            title={'Create Workout'}
            footer={
                <button className='btn btn-primary' onClick={handleSubmit(onEventCreate)}>
                    Add
                </button>
            }
        >
            <form className={styles.form}>
                <div className='form-group'>
                    <label htmlFor='workoutStart'>Starting time</label>
                    <input
                        type='datetime-local'
                        {...register('workoutStart', { required: true })}
                        className='form-control'
                        placeholder='Start Datetime'
                        min={min}
                        max={max}
                    />
                </div>
                <div className='form-group'>
                    <label htmlFor='workoutEnd'>Ending time</label>
                    <input
                        type='datetime-local'
                        {...register('workoutEnd', { required: true })}
                        className='form-control'
                        placeholder='End Datetime'
                        min={min}
                        max={max}
                    />
                </div>
            </form>
        </Modal>
    );
}
