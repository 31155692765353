const Utils = {};

Utils.randomColorGenerator = () => {
    const red = Math.floor(Math.random() * 255);
    const green = Math.floor(Math.random() * 255);
    const blue = Math.floor(Math.random() * 255);

    return { red, green, blue };
};

Utils.arrayfyObject = (obj, { keyToName = 'key' } = {}) => {
    const arr = [];
    for (const [key, value] of Object.entries(obj)) {
        arr.push({ ...value, [keyToName]: key });
    }
    return arr;
};

Utils.dateToDatetimeFormat = date => {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hour = ('0' + date.getHours()).slice(-2);
    const min = ('0' + date.getMinutes()).slice(-2);

    return `${year}-${month}-${day}T${hour}:${min}`;
};

Utils.dateToSimpleDateFormat = date => {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);

    return `${year}-${month}-${day}`;
};

Utils.dateToDatetimeFormatRangeDay = date => {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);

    return { min: `${year}-${month}-${day}T00:00`, max: `${year}-${month}-${day}T21:59` };
};

export default Utils;
