import Avatar from 'react-avatar';

import styles from './UserPreview.module.css';

export default function UserPreview({ user, statusIcon, excludeCSS = false }) {
    return (
        <div className={styles.wrap}>
            <Avatar name={`${user.firstName} ${user.lastName}`} round={true} />
            {statusIcon && <div className={styles.statusIcon}>{statusIcon}</div>}
            <div className={styles.name}>
                {user.firstName} {user.lastName}
            </div>
            <div className={styles.email}>{user.email}</div>
            {!excludeCSS && (
                <div className={styles.about}>
                    {user.currentCSS ? (
                        <div className={styles.aboutCntent} title={user.currentCSS || ''}>
                            CSS: {user.currentCSS?.toFixed(2)}
                        </div>
                    ) : (
                        'CSS not available'
                    )}
                </div>
            )}
        </div>
    );
}
