import { useForm } from 'react-hook-form';

import Modal from '../../../Components/Modal/Modal';

import styles from './CalendarPage.module.css';
import API from '../../../Utils/api';

export default function UpdateWorkout({ data: defaultValues, closeCB, onSuccess, onFailure }) {
    const { register, handleSubmit } = useForm({
        defaultValues: defaultValues
    });

    const onEventUpdate = async ({
        workoutStart,
        workoutEnd,
        trainingDurationMin,
        trainingDurationSec,
        volume
    }) => {
        try {
            const { data } = await API.updateWorkout(defaultValues._id, {
                workoutStart: new Date(workoutStart).toUTCString(),
                workoutEnd: new Date(workoutEnd).toUTCString(),
                trainingDurationMin,
                trainingDurationSec,
                volume
            });
            onSuccess('Workout updated!', data);
        } catch (err) {
            console.log(err);
            onFailure("Couldn't update workout!");
        }
    };

    const onEventRemove = async workout => {
        try {
            await API.removeWorkout(workout._id);
            onSuccess('Workout removed!', defaultValues);
        } catch (err) {
            console.log(err);
            onFailure("Couldn't remove workout!");
        }
    };

    return (
        <Modal
            closeCB={closeCB}
            title={'Update Workout'}
            footer={
                <span className={styles.row}>
                    <button className='btn btn-danger' onClick={() => onEventRemove(defaultValues)}>
                        Remove
                    </button>
                    <button className='btn btn-primary' onClick={handleSubmit(onEventUpdate)}>
                        Update
                    </button>
                </span>
            }
        >
            <form className={styles.form}>
                <div className='form-row'>
                    <div className='form-group'>
                        <label htmlFor='workoutStart'>Starting time</label>
                        <input
                            type='datetime-local'
                            {...register('workoutStart', { required: true })}
                            className='form-control'
                            placeholder='Start Datetime'
                        />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='workoutEnd'>Ending time</label>
                        <input
                            type='datetime-local'
                            {...register('workoutEnd', { required: true })}
                            className='form-control'
                            placeholder='End Datetime'
                        />
                    </div>
                </div>
                <div className='form-row'>
                    <div className='form-group'>
                        <label htmlFor='trainingDurationMin'>Training Duration Min</label>
                        <input
                            type='number'
                            {...register('trainingDurationMin', { required: true })}
                            className='form-control'
                            placeholder='Training Duration Min'
                        />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='trainingDurationSec'>Training Duration Sec</label>
                        <input
                            type='number'
                            {...register('trainingDurationSec', { required: true })}
                            className='form-control'
                            placeholder='Training Duration Sec'
                        />
                    </div>
                </div>
                <div className='form-group'>
                    <label htmlFor='volume'>Volume</label>
                    <input
                        type='number'
                        {...register('volume', { required: true })}
                        className='form-control'
                        placeholder='Volume'
                    />
                </div>
            </form>
        </Modal>
    );
}
