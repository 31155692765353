import { useForm } from 'react-hook-form';
import { FiMapPin } from 'react-icons/fi';
import { IoMailOutline } from 'react-icons/io5';
import { IoCallOutline } from 'react-icons/io5';
import { ToastContainer, toast } from 'react-toastify';
import CustomError from '../../Components/Error/CustomError';
import API from '../../Utils/api';
import { useUser } from '../../Context/useUser';

export default function FeedbackPage() {
    const { user } = useUser();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm();

    const onSubmit = async data => {
        try {
            await API.addFeedback({
                ...data,
                type: 'Feedback',
                name: `${user.firstName} ${user.lastName}`,
                email: user.email
            });
            reset();
            toast.success('Thank you for your feedback!', {
                position: 'bottom-center'
            });
            console.log(data);
        } catch (error) {
            toast.error(`Couldn't send the feedback!`, {
                position: 'bottom-center'
            });
        }
    };

    return (
        <div className='contact'>
            <ToastContainer draggable={'mouse'} />
            <form onSubmit={handleSubmit(onSubmit)} method='post' role='form'>
                <div className='form-group mt-3'>
                    <input
                        type='text'
                        className='form-control'
                        name='subject'
                        id='subject'
                        placeholder='Subject'
                        {...register('subject', { required: 'This field is required' })}
                    />
                    {errors.subject && <CustomError>{errors.subject.message}</CustomError>}
                </div>
                <div className='form-group mt-3'>
                    <textarea
                        className='form-control'
                        name='message'
                        rows='5'
                        placeholder='Message'
                        {...register('message', { required: 'This field is required' })}
                    ></textarea>
                    {errors.message && <CustomError>{errors.message.message}</CustomError>}
                </div>
                <br />
                <div className='text-center'>
                    <button type='submit' className='btn btn-primary'>
                        Send Feedback
                    </button>
                </div>
            </form>
        </div>
    );
}
