import { useForm } from 'react-hook-form';
import styles from './ZonesPage.module.css';
import { useEffect, useState } from 'react';
import API from '../../../Utils/api';
import { useUser } from '../../../Context/useUser';
import Table from '../../../Components/Table/Table';
import { useParams } from 'react-router';
import { useVisitingUser } from '../../../Hooks/useVisitingUser';

const columns = [
    {
        Header: 'Upload Date',
        accessor: 'submissionData',
        Cell: ({ row }) => <div>{new Date(row.original.createdAt).toLocaleDateString('en-GB')}</div>
    },
    {
        Header: 'CSS',
        accessor: 'css',
        Cell: ({ row }) => <div>{row.original.css.toFixed(2)}</div>
    },
    {
        Header: 'Distance (m) for 30 min',
        accessor: 'distance30'
    }
];

export default function T30TestPage() {
    const { user } = useUser();

    const { athleteId } = useParams();

    const visitedUser = useVisitingUser(athleteId);

    const currentUser = athleteId ? visitedUser : user;

    const [testData, setTestData] = useState([]);

    const fetchAndSetData = async currentUser => {
        if (!currentUser) return;
        const { data } = await API.getTest30TestsByUserId(currentUser._id);
        const filteredTestData = data.filter(item => item.time3000 === undefined);
        setTestData(filteredTestData);
    };

    useEffect(() => {
        fetchAndSetData(currentUser);
    }, [currentUser]);

    const { register, handleSubmit } = useForm({
        defaultValues: { time30: 30 }
    });

    const onSubmit = async data => {
        const { data: res } = await API.createTest30Test(currentUser._id, data);
        console.log(res);
        setTestData([res, ...testData]);
    };

    return (
        <div className={styles.pageWrap}>
            <div className={styles.dataCollectionWrap + ' island'}>
                <form className={styles.form}>
                    <div className='form-row'>
                        <div className='form-group'>
                            <label htmlFor='distance30'>Distance (m) *</label>
                            <input
                                type='number'
                                {...register('distance30', { required: true, valueAsNumber: true })}
                                className='form-control'
                            />
                        </div>
                        <div className='form-group'>
                            <label htmlFor='time30'>Time (min)</label>
                            <input
                                type='number'
                                disabled={true}
                                {...register('time30', { required: true })}
                                className='form-control'
                            />
                        </div>
                    </div>
                </form>
                <button className='btn btn-primary' onClick={handleSubmit(onSubmit)}>
                    Submit
                </button>
            </div>
            {testData?.length > 0 && (
                <div className={styles.dataVisualizationWrap + ' island'}>
                    <Table columns={columns} data={testData} />
                </div>
            )}
        </div>
    );
}
